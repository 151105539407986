"use strict";

var module = angular.module("aanstellingspas.gedelegeerde-registreren", [
  "aanstellingspas.service.bedrijfsservice",
  "rt.select2",
  "ngMessages",
  "validexamenblokdate",
]);

module.config(function ($routeProvider) {
  // bind a route to a view
  $routeProvider.when("/gedelegeerde/:id?", {
    templateUrl: "app/modules/gedelegeerde/gedelegeerde-registreren.view.html",
    controller: "GedelegeerdeRegistrerenController",
    title: "Gedelegeerde registeren",
    reloadOnSearch: false,
    navigation_id: "gedelegeerden",
  });
});

module.controller("GedelegeerdeRegistrerenController", [
  "$scope",
  "authService",
  "$http",
  "$location",
  "backendBaseUrl",
  "$log",
  "$resource",
  "$localStorage",
  "notificationservice",
  "$route",
  "$filter",
  "Upload",
  "$routeParams",
  function (
    $scope,
    authService,
    $http,
    $location,
    backendBaseUrl,
    $log,
    $resource,
    $localStorage,
    notificationservice,
    $route,
    $filter,
    Upload,
    $routeParams
  ) {
    alert("Chapeaux");
    /*
     * Does the actual submitting of the data to the server,
     * after checking if gedeleerde and date is available
     */

    $scope.updateOrCreateGedelegeerde = function () {
      var gemeentes = [];
      //get alle gemeentes
      _.each($scope.regios, function (regio) {
        // Array.prototype.push()
        gemeentes = gemeentes.concat(regio.gemeentes);
      });
      //filter them to get the selected ones and get their id's
      $scope.gedelegeerde.voorkeursGemeenten = _.chain(gemeentes)
        .filter("selected")
        .map("id")
        .value();

      var updateRequest = $resource(
        backendBaseUrl + "/gedelegeerde/" + $scope.gedelegeerdeId,
        {},
        {
          update: {
            method: "PUT",
            isArray: false,
          },
        }
      );

      var createRequest = $resource(
        backendBaseUrl + "/gedelegeerde",
        {},
        {
          create: {
            method: "POST",
          },
        }
      );

      //if we have an id, we are updating an existing gedelegeerde. if not, he be new
      if ($scope.gedelegeerdeId) {
        updateRequest
          .update({}, $scope.gedelegeerde)
          .$promise.then(function success() {
            notificationservice.success("Gedelegeerde geupdate");
            $route.reload();
          });
      } else {
        createRequest
          .create({}, $scope.gedelegeerde)
          .$promise.then(function success() {
            notificationservice.success("Gedelegeerde gemaakt");
            $route.reload();
          });
      }
    };

    /*
     * reloads the view
     */
    $scope.reloadView = function () {
      $route.reload();
      $log.debug("reloading the view, you will never see this message");
    };

    $scope.selectRegio = function (regio) {
      if (_.every(regio.gemeentes, "selected")) {
        _.each(regio.gemeentes, function (gemeente) {
          gemeente.selected = false;
        });
      } else {
        _.each(regio.gemeentes, function (gemeente) {
          gemeente.selected = true;
        });
      }
    };
  },
]);
