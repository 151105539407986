var module = angular
  .module("aanstellingspas.messageModal", [])
  .directive("messageModal", [
    "$rootScope",
    "backendBaseUrl",
    "$routeParams",
    "$log",
    "$resource",
    "notificationservice",
    "$route",
    function (
      $rootScope,
      backendBaseUrl,
      $routeParams,
      $log,
      $resource,
      notificationservice,
      $route
    ) {
      return {
        // http://stackoverflow.com/a/21714314/6077862
        restrict: "E",
        transclude: true,
        replace: true,
        templateUrl:
          "app/modules/directives/message-modal/message-modal.view.html",
        scope: {
          commentUrl: "@",
          close: "&", //the function to close the modal, this happens on the parent
        },
        link: function (scope, element, attrs) {
          scope.content = "";
          scope.saveReaction = function (comment) {
            $resource(backendBaseUrl + scope.commentUrl, {}, null)
              .save({
                content: comment,
              })
              .$promise.then(function success() {
                notificationservice.success("Reactie geplaatst");
                scope.close();
                scope.content = "";
                scope.$emit("commented");
              }),
              function failure(result) {
                notificationservice.failure("Reactie plaatsen mislukt");
                $log.error(result);
              };
          };
        },
      };
    },
  ]);
