"use strict";

var module = angular.module("aanstellingspas.htmltest", []);

module.config(function ($routeProvider) {
  // bind a route to a view
  $routeProvider.when("/htmltest", {
    templateUrl: "app/modules/htmltest/htmltest.view.html",
    controller: "HtmlTestController",
    title: "HTML Test Page",
    reloadOnSearch: false,
  });
});

module.controller("HtmlTestController", [
  "$scope",
  "authService",
  function ($scope, authService) {},
]);
