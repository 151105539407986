"use strict";

var module = angular.module("aanstellingspas.examens", ["smart-table"]);

module.config(function ($routeProvider) {
	$routeProvider.when("/examens", {
		templateUrl: "app/modules/examens/examens.view.html",
		controller: "ExamensController",
		title: "Examens",
		navigation_id: "examens",
	});
});

module.controller("ExamensController", [
	"$scope",
	"$http",
	"$log",
	"$localStorage",
	"notificationservice",
	"$resource",
	"backendBaseUrl",
	"$location",
	function ($scope, $http, $log, $localStorage, notificationService, $resource, backendBaseUrl, $location) {
		//collection of all examens from backend
		$scope.collection = [];

		//collection of examens displayed in view
		$scope.displayed = [];

		//show old exams
		$scope.oldExams = false;

		//check if show old exams
		$scope.showOldExams = function () {
			if ($scope.oldExams === true) {
				$resource(backendBaseUrl + "/secretariaatexamen", {
					showOldExams: "true",
				})
					.query()
					.$promise.then(
						function success(result) {
							$scope.collection = result;
						},
						function failure(result) {
							if (result.status === 404) {
								//notificationservice.info('Er zijn geen examens.');
							} else {
								$log.error(result);
							}
						}
					);
			} else {
				$resource(backendBaseUrl + "/secretariaatexamen", {
					showOldExams: "false",
				})
					.query()
					.$promise.then(
						function success(result) {
							$scope.collection = result;
						},
						function failure(result) {
							if (result.status === 404) {
								//notificationservice.info('Er zijn geen examens.');
							} else {
								$log.error(result);
							}
						}
					);
			}
		};

		//get alle examens for overzicht
		$resource(backendBaseUrl + "/secretariaatexamen")
			.query()
			.$promise.then(
				function success(result) {
					$scope.collection = result;

					_.forEach($scope.collection, function (examen) {
						const datum = new Date(examen.datum);
						examen.dateGedelegeerdePlaatsOpleider = [
							datum.getDate() + "-" + (datum.getMonth() + 1) + "-" + datum.getFullYear(),
							examen.gedelegeerde,
							examen.plaats,
							examen.opleider,
						];
					});
				},
				function failure(result) {
					if (result.status === 404) {
						//notificationservice.info('Er zijn geen examens.');
					} else {
						$log.error(result);
					}
				}
			);

		//go to the detail view of examen
		$scope.selectExamen = function (id) {
			$location.path("/examen/" + id);
		};

		$scope.showIfTransportbegeleider = function (type) {
			if (type === "TransportBegeleidersExamenblok") {
				return "TPB";
			} else {
				return "";
			}
		};
	},
]);
module.directive("stSelectDistinct", [
	function () {
		return {
			restrict: "E",
			require: "^stTable",
			scope: {
				collection: "=",
				predicate: "@",
				predicateExpression: "=",
			},
			template:
				'<select2 ng-model="selectedOption" ng-change="optionChanged(selectedOption)" s2-options="opt for opt in distinctItems" options="{language: \'nl\', placeholder: \'Selecteer een regio...\'}"></select2>',
			link: function (scope, element, attr, table) {
				var getPredicate = function () {
					var predicate = scope.predicate;
					if (!predicate && scope.predicateExpression) {
						predicate = scope.predicateExpression;
					}
					return predicate;
				};

				scope.$watch(
					"collection",
					function (newValue) {
						var predicate = getPredicate();

						if (newValue) {
							var temp = [];
							scope.distinctItems = ["Alle"];

							angular.forEach(scope.collection, function (item) {
								var value = item[predicate];

								if (value && value.trim().length > 0 && temp.indexOf(value) === -1) {
									temp.push(value);
								}
							});
							temp.sort();

							scope.distinctItems = scope.distinctItems.concat(temp);
							scope.selectedOption = scope.distinctItems[0];
							scope.optionChanged(scope.selectedOption);
						}
					},
					true
				);

				scope.optionChanged = function (selectedOption) {
					var predicate = getPredicate();

					var query = {};

					query = selectedOption;

					if (query === "Alle") {
						query = null;
					}

					table.search(query, predicate);
				};
			},
		};
	},
]);
