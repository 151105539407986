"use strict";

var module = angular
  .module("aanstellingspas.examenkandidaat", ["rt.select2"])

  .config(function ($routeProvider) {
    $routeProvider.when("/examenkandidaat/:id", {
      templateUrl: "app/modules/examenkandidaat/examenkandidaat.view.html",
      controller: "ExamenKandidaatController",
      title: "Examenkandidaat",
    });
  })

  .controller("ExamenKandidaatController", [
    "$scope",
    "$resource",
    "backendBaseUrl",
    "$window",
    "$location",
    "notificationservice",
    "$route",
    "$routeParams",
    "$log",
    function (
      $scope,
      $resource,
      backendBaseUrl,
      $window,
      $location,
      notificationservice,
      $route,
      $routeParams,
      $log
    ) {
      $scope.kandidaatId = $routeParams.id;
      $scope.examenKandidaat = {};
      $scope.vandaag = new moment().format("L");
      function getKandidaat() {
        $resource(backendBaseUrl + "/deelnemer/:id/beoordelingstatus/", {
          id: $scope.kandidaatId,
        })
          .get()
          .$promise.then(
            function success(result) {
              if (result.theorieGeslaagdDatum) {
                result.theorieGeslaagdDatum = new moment(
                  result.theorieGeslaagdDatum
                ).format("L");
              }
              $scope.examenKandidaat = result;
            },
            function failure(result) {
              if (result.status === 404) {
                notificationservice.info("Kandidaat niet gevonden");
              } else {
                $log.error(result);
              }
            }
          );
      }
      getKandidaat();

      $scope.geslaagdOpties = [
        {
          id: "InAfwachting",
          result: "In afwachting",
        },
        {
          id: "NietGeslaagd",
          result: "Onvoldoende",
        },
        {
          id: "Geslaagd",
          result: "Voldoende",
        },
        {
          id: "NietAanwezig",
          result: "Afwezig",
        },
      ];
      var convertDate = function (dateString) {
        var parts = dateString.split("-");
        var date = new Date(parts[2], parts[1] - 1, parts[0]);
        return date.toISOString();
      };
      $scope.saveKandidaat = function () {
        var model = {};
        if ($scope.examenKandidaat.theorieGeslaagdDatum !== null) {
          model.theorieUitslagDatum = convertDate(
            $scope.examenKandidaat.theorieGeslaagdDatum
          );
        }

        model.theorieUitslag = $scope.examenKandidaat.theorieGeslaagdStatus;
        model.praktijkUitslag = $scope.examenKandidaat.praktijkGeslaagdStatus;

        $resource(backendBaseUrl + "/deelnemer/:id/beoordelingstatus/", {
          id: $scope.kandidaatId,
        })
          .save(model)
          .$promise.then(
            function success() {
              notificationservice.success("Kandidaat opgeslagen");
              $scope.examenkandidaatForm.$setPristine();
              getKandidaat();
            },
            function failure(result) {
              notificationservice.error("Mislukt :(");
            }
          );
      };
      /*
       * reloads the view
       */
      $scope.reloadView = function () {
        $route.reload();
        $log.debug("reloading the view, you will never see this message");
      };

      $scope.copySuccess = function () {
        notificationservice.success("Naar klembord gekopiëerd");
      };
    },
  ]);
