"use strict";

var module = angular.module("aanstellingspas.beoordelingen", [
  "aanstellingspas.service.bedrijfsservice",
  "smart-table",
]);

module.config(function ($routeProvider) {
  $routeProvider.when("/beoordelingen/", {
    templateUrl: "app/modules/beoordelingen/listbeoordelingen.view.html",
    controller: "ListBeoordelingen",
    title: "Beoordelingen",
    navigation_id: "beoordelingen",
    reloadOnSearch: false,
  });
});

module.filter("splitByPipes", function () {
  // splits a string with double pipe delimiters to array for iteration in view
  return function (value) {
    if (!value.length) return;
    return value.split("||");
  };
});

module.controller("ListBeoordelingen", [
  "$scope",
  "authService",
  "$http",
  "$location",
  "backendBaseUrl",
  "$log",
  "$resource",
  "$localStorage",
  "notificationservice",
  "$route",
  "$filter",
  "Upload",
  function (
    $scope,
    authService,
    $http,
    $location,
    backendBaseUrl,
    $log,
    $resource,
    $localStorage,
    notificationservice,
    $route,
    $filter,
    Upload
  ) {
    $resource(backendBaseUrl + "/beoordelingen")
      .query()
      .$promise.then(
        function success(result) {
          $scope.beoordelingen = result;
        },
        function failure(result) {
          $log.error(result);
        }
      );

    $scope.goToGemeenteKeuring = function (id) {
      $location.path("/gemeentegoedkeuring/" + id);
    };

    $scope.goToRijkswaterstaatKeuring = function (id) {
      $location.path("/rijkswaterstaatgebruikergoedkeuring/" + id);
    };

    $scope.goToProvincieKeuring = function (id) {
      $location.path("/provinciegoedkeuring/" + id);
    };

    $scope.goToBvoKeuring = function (id) {
      $location.path("/keurbvo/" + id);
    };

    $scope.goToPasaanvraagKeuren = function (id) {
      $location.path("/pasaanvraagkeuren/" + id);
    };
  },
]);
