"use strict";

var module = angular
  .module("aanstellingspas.login", ["ngMessages"])

  .config(function ($routeProvider) {
    $routeProvider.when("/login", {
      templateUrl: "app/modules/login/login.view.html",
      controller: "loginController",
      title: "Inloggen",
      allowUnauthorized: true,
    });
  });

module.controller("loginController", [
  "$scope",
  "authService",
  "$location",
  "$rootScope",
  "$routeParams",
  "$localStorage",
  "$resource",
  "backendBaseUrl",
  "$timeout",
  function (
    $scope,
    authService,
    $location,
    $rootScope,
    $routeParams,
    $localStorage,
    $resource,
    backendBaseUrl,
    $timeout
  ) {
    // always redirects user to dashboard if he's logged in
    if ($rootScope.loggedin) {
      $location.path("/dashboard");
    }

    // guess: enables angularjs's validation to enable login button ??
    $scope.loginModel = {
      username: "",
      password: "",
    };

    $scope.login = function () {
      var redirectTo = $routeParams.redirectTo;

      if (!redirectTo || redirectTo === null) {
        redirectTo = "/dashboard";
      }

      authService.login(
        $scope.loginModel.username,
        $scope.loginModel.password,
        redirectTo
      );
    };
  },
]);
