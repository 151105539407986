"use strict";

var module = angular.module("aanstellingspas.faq", []);

module.config(function ($routeProvider) {
  $routeProvider.when("/faq", {
    templateUrl: "app/modules/faq/faq.view.html",
    controller: "FaqController",
    title: "Faq",
  });
});

module.controller("FaqController", [
  "$scope",
  "$http",
  "$log",
  "$localStorage",
  "notificationservice",
  "$resource",
  "backendBaseUrl",
  function (
    $scope,
    $http,
    $log,
    $localStorage,
    notificationService,
    $resource,
    backendBaseUrl
  ) {
    getFaqs();

    $scope.save = function () {
      save();
    };

    $scope.add = function () {
      add();
    };

    /**
     * Saves the current ordering
     **/
    function save() {
      var request = $resource(
        backendBaseUrl + "/faq",
        {},
        {
          saveData: {
            method: "PUT",
            isArray: true,
          },
        }
      );
      var faqModel = {};
      faqModel.faqs = $scope.list;
      request.saveData({}, faqModel).$promise.then(function success() {
        getFaqs();
      });
    }

    /** Adds a new FAQ to the database,
     *   with the highest ranking available,
     *   meaning it will be at the bottom
     **/
    function add() {
      var request = $resource(
        backendBaseUrl + "/faq",
        {},
        {
          saveData: {
            method: "POST",
            isArray: false,
          },
        }
      );
      var newFaq = {};
      newFaq.question = $scope.newFaq.question;
      newFaq.answer = $scope.newFaq.answer;
      request.saveData({}, newFaq).$promise.then(function success() {
        getFaqs();
      });
    }

    /**
     * Gets all FAQs from backend
     **/
    function getFaqs() {
      // getting next approvable bvo from queue in database (first in first out)
      $resource(backendBaseUrl + "/faq")
        .query()
        .$promise.then(
          function success(result) {
            $scope.list = result;
          },
          function failure(result) {
            if (result.status === 404) {
              notificationservice.info("Fout bij ophalen");
            } else {
              $log.error(result);
            }
          }
        );
    }
  },
]);
