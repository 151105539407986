"use strict";

var module = angular.module("aanstellingspas.accountbeheer", ["smart-table"]);

module.config(function ($routeProvider) {
  $routeProvider.when("/accountbeheer", {
    templateUrl: "app/modules/accountbeheer/accountbeheer.view.html",
    controller: "AccountbeheerController",
    title: "Account beheer",
    navigation_id: "accountbeheer",
  });
});

module.controller("AccountbeheerController", [
  "$scope",
  "$http",
  "$log",
  "$localStorage",
  "notificationservice",
  "$resource",
  "backendBaseUrl",
  "$location",
  "$routeParams",
  "$timeout",
  function (
    $scope,
    $http,
    $log,
    $localStorage,
    notificationService,
    $resource,
    backendBaseUrl,
    $location,
    $routeParams,
    $timeout
  ) {
    $scope.query = $routeParams.query;

    search();

    $scope.onSubmit = function () {
      $location.search("query", $scope.query);
      search();
    };

    function search() {
      var queryRequest = {
        query: $scope.query,
      };

      if (!!$scope.query && $scope.query.length > 0) {
        $resource(
          backendBaseUrl + "/account/search/",
          {},
          {
            search: {
              method: "POST",
            },
          }
        )
          .search(queryRequest)
          .$promise.then(
            function success(result) {
              $timeout(function () {
                $scope.collection = result.accounts;
              });
            },
            function failure(result) {
              $log.error(result);
            }
          );
      }
    }
    //collection of all accounts from backend
    $scope.collection = [];

    //collection of accounts displayed in view
    $scope.displayed = [];

    //get alle gedelegeerden for overzicht
    // $resource(backendBaseUrl + '/account')
    //     .get()
    //     .$promise
    //     .then(function success(result) {
    //         $scope.collection = result.accounts;

    //     }, function failure(result) {

    //         $log.error(result);

    //     });
  },
]);
