"use strict";

var module = angular.module("aanstellingspas.plaats", ["rt.select2", "ngMessages", "validexamenblokdate"]);

module.config(function ($routeProvider) {
	// bind a route to a view
	$routeProvider.when("/plaats/:id?", {
		templateUrl: "app/modules/plaats/plaats.view.html",
		controller: "GedelegeerdPlaatsControllereController",
		title: "Plaats",
		reloadOnSearch: false,
		navigation_id: "plaatsen",
	});
});

module.controller("GedelegeerdPlaatsControllereController", [
	"$scope",
	"authService",
	"$http",
	"$location",
	"backendBaseUrl",
	"$log",
	"$resource",
	"$localStorage",
	"notificationservice",
	"$route",
	"$filter",
	"Upload",
	"$routeParams",
	"$rootScope",
	function (
		$scope,
		authService,
		$http,
		$location,
		backendBaseUrl,
		$log,
		$resource,
		$localStorage,
		notificationservice,
		$route,
		$filter,
		Upload,
		$routeParams,
		$rootScope
	) {
		$scope.plaatsId = $routeParams.id;
		$scope.updatingPlaats = false;
		if ($scope.plaatsId) {
			$scope.updatingPlaats = true;
		}

		$scope.backendBaseUrl = backendBaseUrl;
		$scope.plaats = {};
		$scope.regios = [];
		$scope.provincies = [];
		$scope.kruispunten = [];
		$scope.gedelegeerden = [];

		//if an id was found in the routeparams, we are editing an existing plaats. Otherwise its a new one and we use the empty model.
		if ($scope.plaatsId) {
			$resource(backendBaseUrl + "/secretariaatplaats/" + $scope.plaatsId)
				.get()
				.$promise.then(
					function success(result) {
						$scope.plaats = result;
					},
					function failure(result) {
						if (result.status === 404) {
							notificationservice.info("Plaats niet gevonden");
							$location.path("/plaatsen");
						} else {
							$log.error(result);
						}
					}
				);
		}

		$resource(backendBaseUrl + "/secretariaat-regio")
			.query()
			.$promise.then(
				function success(regioresult) {
					$scope.regios = regioresult;
				},
				function failure(result) {
					$log.error(result);
				}
			);

		$resource(backendBaseUrl + "/secretariaatprovincie")
			.query()
			.$promise.then(
				function success(result) {
					$scope.provincies = result;
				},
				function failure(result) {
					$log.error(result);
				}
			);
		/*
		 * Does the actual submitting of the data to the server,
		 * after checking if plaats and date is available
		 */

		$scope.updateOrCreatePlaats = function () {
			var updateRequest = $resource(
				backendBaseUrl + "/secretariaatplaats/" + $scope.plaatsId,
				{},
				{
					update: {
						method: "PUT",
						isArray: false,
					},
				}
			);

			var createRequest = $resource(
				backendBaseUrl + "/secretariaatplaats",
				{},
				{
					create: {
						method: "POST",
					},
				}
			);

			//if we have an id, we are updating an existing plaats. if not, he be new
			if ($scope.plaatsId) {
				updateRequest.update({}, $scope.plaats).$promise.then(function success() {
					notificationservice.success("Plaats geupdate");
					$route.reload();
				});
			} else {
				createRequest.create({}, $scope.plaats).$promise.then(
					function success() {
						notificationservice.success("Plaats gemaakt");
						$route.reload();
					},
					function failure(result) {
						if (result.status === 409) {
							notificationservice.error("Plaats bestond al");
						}
					}
				);
			}
		};

		/*
		 * reloads the view
		 */
		$scope.reloadView = function () {
			$route.reload();
			$log.debug("reloading the view, you will never see this message");
		};
		$scope.deletePlaats = function () {
			swal({
				title: "Verwijderen",
				text: "Wilt u deze plaats verwijderen?",
				icon: "info",

				buttons: {
					confirm: {
						text: "Ja",
						value: true,
						visible: true,
						className: "",
						closeModal: true,
					},
					cancel: {
						text: "Nee",
						value: false,
						visible: true,
						className: "",
						closeModal: true,
					},
				},
			}).then(
				function confirmAction(confirmed) {
					if (confirmed == true) {
						$resource(backendBaseUrl + "/secretariaatplaats/" + $scope.plaatsId)
							.delete()
							.$promise.then(
								function success(result) {
									notificationservice.success("Plaats verwijderd");
									$location.path("/plaatsen");
								},
								function failure(result) {
									$log.error(result);
								}
							);
					}
				},
				function cancelAction() {}
			);
		};
	},
]);
