"use strict";

var module = angular.module("aanstellingspas.pasaanvraagkeuren", [
	"aanstellingspas.service.bedrijfsservice",
	"smart-table",
	"ngCropper",
]);

module.config(function ($routeProvider) {
	$routeProvider.when("/pasaanvraagkeuren/:id", {
		templateUrl: "app/modules/pasaanvraagkeuren/pasaanvraagkeuren.view.html",
		controller: "KeurPasaanvraagController",
		title: "Pasaanvraag Keuren",
		navigation_id: "pasaanvraagkeuren",
		reloadOnSearch: false,
	});

	$routeProvider.when("/pasaanvraagkeuren/", {
		templateUrl: "app/modules/pasaanvraagkeuren/listpasaanvragen.view.html",
		controller: "ListPasaanvragen",
		title: "Pasaanvraag Keuren",
		navigation_id: "pasaanvraagkeuren",
		reloadOnSearch: false,
	});
});

module.filter("splitByPipes", function () {
	// splits a string with double pipe delimiters to array for iteration in view
	return function (value) {
		if (!value.length) return;
		return value.split("||");
	};
});

module.controller("ListPasaanvragen", [
	"$scope",
	"authService",
	"$http",
	"$location",
	"backendBaseUrl",
	"$log",
	"$resource",
	"$localStorage",
	"notificationservice",
	"$route",
	"$filter",
	"Upload",
	function (
		$scope,
		authService,
		$http,
		$location,
		backendBaseUrl,
		$log,
		$resource,
		$localStorage,
		notificationservice,
		$route,
		$filter,
		Upload
	) {
		$resource(backendBaseUrl + "/approvable-pasaanvraag")
			.query()
			.$promise.then(
				function success(result) {
					$scope.pasaanvragen = result;
				},
				function failure(result) {
					$log.error(result);
				}
			);

		$scope.openKeuring = function (id) {
			$location.path("/pasaanvraagkeuren/" + id);
		};

		$scope.isGemeente = function (aanvragerNaam) {
			return _.includes(aanvragerNaam, "Gemeente");
		};
	},
]);

module
	.controller("KeurPasaanvraagController", [
		"$scope",
		"authService",
		"$http",
		"$location",
		"backendBaseUrl",
		"$log",
		"$resource",
		"$localStorage",
		"notificationservice",
		"$route",
		"$filter",
		"Upload",
		"$routeParams",
		"$rootScope",
		"$window",
		function (
			$scope,
			authService,
			$http,
			$location,
			backendBaseUrl,
			$log,
			$resource,
			$localStorage,
			notificationservice,
			$route,
			$filter,
			Upload,
			$routeParams,
			$rootScope,
			$window
		) {
			$scope.keuringAcknowledged = "";
			$scope.pasTypeToCheck = "";
			$scope.backendBaseUrl = backendBaseUrl;
			$scope.viewHistorie = false;
			$scope.werkgevers = [];
			$scope.selectedPassen = [];
			$scope.snoozeComment = "";
			$scope.keurstatusFinal;
			$scope.ingevoerdePasnummer = "";

			$scope.pasfotoHasBeenCropped = false;

			$scope.vorigePasModel = {
				noPasMatchFound: null,
				selectedNewPasId: null,
				selectedLegacyPasId: null,
			};

			$scope.$on("commented", function () {
				$scope.loadAanvraag();
			});

			$scope.$on("snoozed", function () {
				$scope.loadAanvraag();
			});

			$scope.isGemeente = function () {
				if (angular.isDefined($scope.aanvraag)) {
					return _.includes($scope.aanvraag.bedrijfsNaam, "Gemeente");
				} else {
					return false;
				}
			};

			$rootScope.$on("pasfoto-cropped", function () {
				$log.debug("pasfoto cropped");
				$scope.pasfotoHasBeenCropped = true;
			});

			// set default aanvraagkeuring op 'terugsturen'
			$scope.terugsturenOfAfkeuren = "terugsturen";

			$scope.updateAanvraagType = function () {
				$resource(backendBaseUrl + "/approvable-pasaanvraag/" + $routeParams.id)
					.put()
					.$promise.then(function success() {});
			};

			$scope.loadAanvraag = function () {
				$resource(backendBaseUrl + "/approvable-pasaanvraag/" + $routeParams.id)
					.get()
					.$promise.then(
						function success(result) {
							$scope.aanvraag = result;
							$scope.keurstatusFinal = result.statusFinal;
							$scope.pasTypeToCheck = $scope.getFriendlySubtype(result.subType) + " - " + result.type;
							if (result.ingevoerdePasnummer == null) {
								$scope.ingevoerdePasnummer = "geen pasnummer ingevoerd";
							} else {
								$scope.ingevoerdePasnummer = result.ingevoerdePasnummer;
							}
							// if we have a vog
							if (_.some($scope.aanvraag.aanvraagPogingen[0].onderdelen, ["type", "vog"])) {
								var vog = _.find($scope.aanvraag.aanvraagPogingen[0].onderdelen, ["type", "vog"]);
								vog.aanvraagDatum = $scope.aanvraag.aanvraagDatum;
							}
							// we apply different sorting for eersteaanvraag
							if ($scope.aanvraag.subType === "eersteAanvraag") {
								$scope.aanvraag.aanvraagPogingen[0].onderdelen = _.orderBy(
									$scope.aanvraag.aanvraagPogingen[0].onderdelen,
									[
										function (onderdeel) {
											switch (onderdeel.type) {
												case "persoonsgegevens":
													onderdeel.geboorteDatum = onderdeel.geboorteDatum.substring(
														0,
														onderdeel.geboorteDatum.indexOf(" ") + 1
													);
													return 1;
													break;
												case "vog":
													return 5;
													break;
												case "code95":
													return 8;
													break;
												case "waarmerk":
													return 10;
													break;
												case "pasfoto":
													return 15;
													break;
												default:
													return 100;
													break;
											}
										},
									]
								);
							} else {
								$scope.aanvraag.aanvraagPogingen[0].onderdelen = _.orderBy(
									$scope.aanvraag.aanvraagPogingen[0].onderdelen,
									[
										function (onderdeel) {
											switch (onderdeel.type) {
												case "persoonsgegevens":
													onderdeel.geboorteDatum = onderdeel.geboorteDatum.substring(
														0,
														onderdeel.geboorteDatum.indexOf(" ") + 1
													);
													return 1;
													break;
												case "werknemersVerklaring":
													return 5;
													break;
												case "pasfoto":
													return 15;
													break;
												default:
													return 100;
													break;
											}
										},
									]
								);
							}
						},
						function failure(result) {
							$log.error(result);
						}
					);
			};

			$scope.loadAanvraag();

			$scope.getFriendlySubtype = function (subtype) {
				switch (subtype) {
					case "duplicaat":
						return "Duplicaat";
						break;
					case "verlenging":
						return "Verlenging";
						break;
					case "omzettingBijplaatsing":
						return "Omzetting/bijplaatsing";
						break;
					case "eersteAanvraag":
						return "Eerste aanvraag";
						break;
					default:
						return "Onbekend";
				}
			};
			/*
			 * Aanvraag goedkeuren
			 *
			 */
			$scope.keurAanvraagGoed = function () {
				var pasfotoOnderdelen = _.filter($scope.aanvraag.aanvraagPogingen[0].onderdelen, ["type", "pasfoto"]);

				if (
					pasfotoOnderdelen.length > 0 &&
					pasfotoOnderdelen[0].keurStatus === "Goedgekeurd" &&
					$scope.pasfotoHasBeenCropped
				) {
					$log.debug(
						"pasfoto was marked as valid, and has been edited by secretariaat, uploading modified pasfoto"
					);

					uploadNewPasfoto(
						$scope.aanvraag.id,
						pasfotoOnderdelen[0].url,
						pasfotoOnderdelen[0].originalFilename
					).then(
						function (response) {
							$log.debug("pasfoto upload complete", response);

							// we must wait for the upload to complete before keuring goed, to prevent a race condition
							putGoedkeuring();
						},
						function (response) {
							$log.debug("pasfoto upload error", response);
						},
						function (evt) {
							// Math.min is to fix IE which reports 200% sometimes
							$log.debug(
								"pasfoto upload progress",
								Math.min(100, parseInt((100.0 * evt.loaded) / evt.total))
							);
						}
					);
				} else {
					putGoedkeuring();
				}
			};

			/*
			 * Aanvraag DEFINITIEF afkeuren (dus niet terugsturen)
			 *
			 */
			$scope.keurAanvraagAf = function () {
				//upload the pasfoto if it has been cropped and approved
				if ($scope.pasfotocheck === "valid" && $scope.pasfotoHasBeenCropped) {
					$log.debug(
						"pasfoto was marked as valid, and has been edited by secretariaat, uploading modified pasfoto"
					);
					uploadNewPasfoto(
						$scope.aanvraag.id,
						$scope.aanvraag.aanvraagPogingen[0].pasfotoUrl,
						$scope.aanvraag.aanvraagPogingen[0].pasfotoOriginalFileName
					).then(
						function (response) {
							$log.debug("pasfoto upload complete", response);

							// we must wait for the upload to complete before keuring af, to prevent a race condition
							putAfkeuring();
						},
						function (response) {
							$log.debug("pasfoto upload error", response);
						},
						function (evt) {
							// Math.min is to fix IE which reports 200% sometimes
							$log.debug(
								"pasfoto upload progress",
								Math.min(100, parseInt((100.0 * evt.loaded) / evt.total))
							);
						}
					);
				} else {
					putAfkeuring();
				}
			};

			/*
			 * Aanvraag niet goedkeuren maar terugsturen ter verbetering
			 */
			$scope.stuurAanvraagTerug = function (convertToType) {
				var pasfotoOnderdelen = _.filter($scope.aanvraag.aanvraagPogingen[0].onderdelen, ["type", "pasfoto"]);
				if (
					pasfotoOnderdelen.length > 0 &&
					pasfotoOnderdelen[0].keurStatus === "Goedgekeurd" &&
					$scope.pasfotoHasBeenCropped
				) {
					$log.debug(
						"pasfoto was marked as valid, and has been edited by secretariaat, uploading modified pasfoto"
					);

					uploadNewPasfoto(
						$scope.aanvraag.id,
						pasfotoOnderdelen[0].url,
						pasfotoOnderdelen[0].originalFilename
					).then(
						function (response) {
							$log.debug("pasfoto upload complete", response);

							// we must wait for the upload to complete before keuring goed, to prevent a race condition
							putTerugsturing(convertToType);
						},
						function (response) {
							$log.debug("pasfoto upload error", response);
						},
						function (evt) {
							// Math.min is to fix IE which reports 200% sometimes
							$log.debug(
								"pasfoto upload progress",
								Math.min(100, parseInt((100.0 * evt.loaded) / evt.total))
							);
						}
					);
				} else {
					putTerugsturing(convertToType);
				}
			};

			$scope.openSnoozeModal = function () {
				$scope.showSnoozeModal = true;
			};

			$scope.closeSnoozeModal = function () {
				$scope.showSnoozeModal = false;
			};

			$scope.openMessageModal = function () {
				$scope.showMessageModal = true;
			};

			$scope.snooze = function () {
				$resource(
					backendBaseUrl + "/pasaanvraag/:aanvraagId/snooze",
					{
						aanvraagId: $scope.aanvraag.id,
					},
					null
				)
					.save({
						snoozeComment: $scope.snoozeComment,
					})
					.$promise.then(function success() {
						notificationservice.success("Aanvraag gesnoozed");
						gotoNextAanvraag();
					});
			};

			$scope.getAfgekeurdeOnderdelen = function () {
				if (_.isUndefined($scope.aanvraag)) return [];

				return _.filter($scope.aanvraag.aanvraagPogingen[0].onderdelen, ["keurStatus", "Afgekeurd"]);
			};

			$scope.allValid = function () {
				if (_.isUndefined($scope.aanvraag)) return true;

				if (
					_.filter($scope.aanvraag.aanvraagPogingen[0].onderdelen, ["keurStatus", "Goedgekeurd"]).length ==
					$scope.aanvraag.aanvraagPogingen[0].onderdelen.length
				) {
					if (
						$scope.aanvraag.subType === "omzettingBijplaatsing" ||
						$scope.aanvraag.subType === "duplicaat" ||
						$scope.aanvraag.subType === "verlenging"
					) {
						if ($scope.selectedPassen.length === 0) {
							return false;
						}
						if ($scope.werkgevers.length === 0) {
							return false;
						}
						return true;
					}
					return true;
				}

				return false;
			};

			$scope.allFilledIn = function () {
				if (_.isUndefined($scope.aanvraag)) return true;

				if (
					_.filter($scope.aanvraag.aanvraagPogingen[0].onderdelen, ["keurStatus", "NogNietGekeurd"]).length ==
					0
				) {
					return true;
				}

				return false;
			};

			var putAfkeuring = function () {
				$resource(
					backendBaseUrl + "/approvable-pasaanvraag/:aanvraagId/definitieveafkeuring",
					{
						aanvraagId: $scope.aanvraag.id,
					},
					{
						put: {
							method: "PUT",
						},
					}
				)
					.put({
						onderdelen: $scope.aanvraag.aanvraagPogingen[0].onderdelen,
					})
					.$promise.then(function success() {
						notificationservice.success("Aanvraag definitief geweigerd");
						gotoNextAanvraag();
					});
			};

			var putTerugsturing = function (convertToType) {
				switch (convertToType) {
					case "convertToOmzetting":
						$scope.omzettingType = "ConverteerNaarOmzetting";
						break;
					case "convertToVerlenging":
						$scope.omzettingType = "AddVerlenging";
						break;
					default:
						$scope.omzettingType = "None";
				}

				$resource(
					backendBaseUrl + "/approvable-pasaanvraag/:aanvraagId/terugsturenterverbetering",
					{
						aanvraagId: $scope.aanvraag.id,
					},
					{
						put: {
							method: "PUT",
						},
					}
				)
					.put({
						onderdelen: $scope.aanvraag.aanvraagPogingen[0].onderdelen,
						omzettingOrVerlengingKind: $scope.omzettingType,
					})
					.$promise.then(function success() {
						notificationservice.success("Aanvraag afgekeurd");
						gotoNextAanvraag();
					});
			};

			var putGoedkeuring = function () {
				// do the actual keuring
				$resource(
					backendBaseUrl + "/approvable-pasaanvraag/:aanvraagId/goedkeuring",
					{
						aanvraagId: $scope.aanvraag.id,
					},
					{
						put: {
							method: "PUT",
						},
					}
				)
					.put({
						pasIds: getEnabledKeys($scope.vorigePasModel.selectedNewPasId),
						legacyPasIds: getEnabledKeys($scope.vorigePasModel.selectedLegacyPasId),
						organisaties: $scope.werkgevers,
					})
					.$promise.then(function success() {
						notificationservice.success("Aanvraag goedgekeurd");
						gotoNextAanvraag();
					});
			};

			function getEnabledKeys(array) {
				var result = [];
				_.forEach(array, function (value, key) {
					if (value === true) {
						result.push(key);
					}
				});
				return result;
			}
			var gotoNextAanvraag = function () {
				$http({
					method: "GET",
					url: backendBaseUrl + "/approvable-pasaanvraag/nextid",
				}).then(
					function success(result) {
						$location.path("/pasaanvraagkeuren/" + result.data);
						$window.scrollTo(0, 0);
					},
					function error(result) {
						if (result.status === 404) {
							$location.path("/pasaanvraagkeuren");
						} else {
							$log.error(result);
						}
					}
				);
			};

			var uploadNewPasfoto = function (aanvraagId, imageB64, originalFilename) {
				var blob = Upload.dataUrltoBlob(imageB64, originalFilename);

				return Upload.upload({
					url: backendBaseUrl + "/approvable-pasaanvraag/" + aanvraagId + "/newpasfoto",
					method: "POST",
					data: {
						file: blob,
					},
				});
			};
		},
	])

	.directive("imageonload", function () {
		// http://stackoverflow.com/a/25609847/6077862

		return {
			restrict: "A",
			link: function (scope, element, attrs) {
				element.bind("load", function () {
					//call the function that was passed
					scope.$apply(attrs.imageonload);
				});
			},
		};
	})

	.directive("keurvinkjes", [
		function () {
			return {
				restrict: "E",
				scope: {
					onderdeel: "=",
					keuringAcknowledged: "=",
					name: "=",
					blockGoedkeuring: "=",
					keurstatusFinal: "=",
				},
				templateUrl: "app/modules/pasaanvraagkeuren/templates/keurvinkjes.tpl.html",
				link: function (scope, element, attrs) {
					scope.goedgekeurd = function () {
						scope.keuringAcknowledged = false;
						scope.onderdeel.afkeurReden = null;
					};

					scope.afgekeurd = function () {
						scope.keuringAcknowledged = false;
					};
				},
			};
		},
	])

	.directive("paskeuringPersoonsgegevens", [
		"$resource",
		"backendBaseUrl",
		"notificationservice",
		function ($resource, backendBaseUrl, notificationservice) {
			return {
				restrict: "E",
				scope: {
					onderdeel: "=",
					keuringAcknowledged: "=",
					keurstatusFinal: "=",
				},
				templateUrl: "app/modules/pasaanvraagkeuren/templates/persoonsgegevens.tpl.html",
				link: function (scope, element, attrs) {
					scope.savePersoonsgegevens = function (onderdeel, form) {
						var parts = onderdeel.geboorteDatum.split("-");
						var geboorteDatum = new Date(parts[2], parts[1] - 1, parts[0]);
						$resource(
							backendBaseUrl + "/persoonsgegevens/:onderdeelId",
							{
								onderdeelId: onderdeel.id,
							},
							{
								put: {
									method: "PUT",
								},
							}
						)
							.put({
								voorletters: onderdeel.voorletters,
								tussenvoegsel: onderdeel.tussenvoegsel,
								achternaam: onderdeel.achternaam,
								geboortedatum: geboorteDatum.toISOString(),
							})
							.$promise.then(function success() {
								scope.editingGeboortedatum = false;
								scope.editingVoorletters = false;
								scope.editingTussenvoegsel = false;
								scope.editingAchternaam = false;
								notificationservice.success("Gegevens opgeslagen");
							}),
							function failure() {
								notificationservice.error("Kon gegevens niet opslaan");
							};
					};
				},
			};
		},
	])

	.directive("paskeuringWaarmerk", [
		function () {
			return {
				restrict: "E",
				scope: {
					onderdeel: "=",
					keuringAcknowledged: "=",
					keurstatusFinal: "=",
				},
				templateUrl: "app/modules/pasaanvraagkeuren/templates/legacywaarmerk.tpl.html",
				link: function (scope, element, attrs) {},
			};
		},
	])
	.directive("paskeuringGemeentewerkgever", [
		function () {
			return {
				restrict: "E",
				scope: {
					onderdeel: "=",
					keuringAcknowledged: "=",
					keurstatusFinal: "=",
				},
				templateUrl: "app/modules/pasaanvraagkeuren/templates/gemeentewerkgever.tpl.html",
				link: function (scope, element, attrs) {},
			};
		},
	])

	.directive("paskeuringGemeenteaanstelling", [
		function () {
			return {
				restrict: "E",
				scope: {
					onderdeel: "=",
					keuringAcknowledged: "=",
					keurstatusFinal: "=",
				},
				templateUrl: "app/modules/pasaanvraagkeuren/templates/gemeenteaanstelling.tpl.html",
				link: function (scope, element, attrs) {},
			};
		},
	])

	.directive("paskeuringPasfoto", [
		"Upload",
		"$timeout",
		"$log",
		"backendBaseUrl",
		"$rootScope",
		function (Upload, $timeout, $log, backendBaseUrl, $rootScope, Cropper) {
			return {
				restrict: "E",
				scope: {
					onderdeel: "=",
					keuringAcknowledged: "=",
					keurstatusFinal: "=",
				},
				templateUrl: "app/modules/pasaanvraagkeuren/templates/pasfoto.tpl.html",
				link: function (scope, element, attrs) {
					scope.onderdeel.url = backendBaseUrl + "/image/" + scope.onderdeel.url;
					scope.pasfotoCropperUrl = scope.onderdeel.url;
					scope.previewData;

					scope.initCropper = function () {
						$timeout(showCropper, 100);
					};

					/*
					 * Initiates the cropping and closes the dialog
					 */
					scope.cropAndClose = function () {
						scope.applyImageManipulation();
						scope.onderdeel.url = scope.previewData;
						$rootScope.$broadcast("pasfoto-cropped");
						scope.showCropper = false;
						scope.pasfotoHasBeenCropped = true;
					};

					var imageData;

					/**
					 * Croppers container object should be created in controller's scope
					 * for updates by directive via prototypal inheritance.
					 * Pass a full proxy name to the `ng-cropper-proxy` directive attribute to
					 * enable proxing.
					 */
					scope.cropper = {};
					scope.cropperProxy = "cropper.first";

					scope.showEvent = "show";
					scope.hideEvent = "hide";

					function showCropper() {
						scope.$broadcast(scope.showEvent);
					}
					function hideCropper() {
						scope.$broadcast(scope.hideEvent);
					}
					/*
					 * Calculates the aspectratio of the pasfoto and sets it to the scope
					 */
					scope.calculatePasfotoAspectRatio = function () {
						var aspectratio =
							Math.round(
								(document.getElementsByClassName("pasfoto-thumb")[0].naturalHeight /
									document.getElementsByClassName("pasfoto-thumb")[0].naturalWidth) *
									100
							) / 100;

						$log.debug("aspectratio: ", aspectratio);

						scope.pasfotoAspectRatio = aspectratio;
					};

					scope.rotate = function (degrees) {
						// scope.cropper.first("rotate", { degrees: degrees });
						scope.cropper.first("rotate", degrees);
						scope.applyImageManipulation();
					};

					/*
					 * This generates the actual data (in base64) that the cropper outputs. The output can be used for previews as well as the endresult.
					 */
					scope.applyImageManipulation = function () {
						scope.previewData = scope.cropper
							.first("getCroppedCanvas", {
								width: 150,
								height: 150,
							})
							.toDataURL();
					};
					/**
					 * Object is used to pass options to initalize a cropper.
					 * More on options - https://github.com/fengyuanchen/cropper#options
					 */
					scope.options = {
						maximize: false,
						aspectRatio: 3 / 4,
						zoomable: true,
						rotate: function (dataNew) {
							imageData = dataNew;
						},
						crop: function (dataNew) {
							imageData = dataNew;
						},
					};
				},
			};
		},
	])

	.directive("paskeuringVog", [
		"backendBaseUrl",
		function (backendBaseUrl) {
			return {
				restrict: "E",
				scope: {
					onderdeel: "=",
					keuringAcknowledged: "=",
					keurstatusFinal: "=",
				},
				templateUrl: "app/modules/pasaanvraagkeuren/templates/vog.tpl.html",
				link: function (scope, element, attrs) {
					scope.onderdeel.url = backendBaseUrl + "/image/" + scope.onderdeel.url;
				},
			};
		},
	])
	.directive("paskeuringAangifte", [
		"backendBaseUrl",
		function (backendBaseUrl) {
			return {
				restrict: "E",
				scope: {
					onderdeel: "=",
					keuringAcknowledged: "=",
					keurstatusFinal: "=",
				},
				templateUrl: "app/modules/pasaanvraagkeuren/templates/aangifte.tpl.html",
				link: function (scope, element, attrs) {
					scope.onderdeel.url = backendBaseUrl + "/image/" + scope.onderdeel.url;
				},
			};
		},
	])

	.directive("paskeuringWerknemersverklaring", [
		"backendBaseUrl",
		function (backendBaseUrl) {
			return {
				restrict: "E",
				scope: {
					onderdeel: "=",
					keuringAcknowledged: "=",
					keurstatusFinal: "=",
				},
				templateUrl: "app/modules/pasaanvraagkeuren/templates/werknemersverklaring.tpl.html",
				link: function (scope, element, attrs) {
					scope.onderdeel.url = backendBaseUrl + "/image/" + scope.onderdeel.url;
				},
			};
		},
	])

	.directive("paskeuringLegacyPassen", [
		"backendBaseUrl",
		"$resource",
		"$log",
		"$timeout",
		function (backendBaseUrl, $resource, $log, $timeout) {
			return {
				restrict: "E",
				scope: {
					keuringAcknowledged: "=",
					aanvraagId: "=",
					model: "=",
					aanvrager: "=",
					werkgevers: "=",
					selectedPassen: "=",
					subType: "=",
					keurstatusFinal: "=",
					ingevoerdePasnummer: "=",
				},
				templateUrl: "app/modules/pasaanvraagkeuren/templates/legacypassen.tpl.html",
				link: function (scope, element, attrs) {
					scope.today = moment();
					scope.gekozenType = "";
					$resource(backendBaseUrl + "/approvable-pasaanvraag/" + scope.aanvraagId + "/vorigepassen")
						.get()
						.$promise.then(
							function success(result) {
								scope.legacyPassen = result;
								scope.originalLegacyPassen = result;
								scope.aanvraagType = result.aanvraagType;
							},
							function failure(result) {
								$log.error(result);
							}
						);
					if (scope.subType !== "omzettingBijplaatsing") {
						scope.gekozenType = "irrelevant";
					}

					scope.isVerlengingAangeraden = function (pas) {
						// verlenging checks are only needed for omzettingBijplaatsing
						if (scope.subType == "omzettingBijplaatsing") {
							if (moment(pas.geldigTot).isBetween(moment(), moment().add(6, "months"))) {
								return true;
							}
						}
						return false;
					};

					scope.getGeldigDate = function (pas) {
						if (pas.gebruiktCoulanceRegeling) {
							return moment("2021-06-01", "YYYY-MM-DD").format("DD-MM-YYYY");
						}
						if (pas.kanNogVerlengen) {
							return moment(pas.geldigTot).add(6, "months").format("DD-MM-YYYY");
						} else {
							return moment(pas.geldigTot).format("DD-MM-YYYY");
						}
					};
					scope.$watch("gekozenType", function (newValue) {
						if (newValue === "" || _.isUndefined(newValue)) {
							return;
						}
						//werkgevers zijn nog niet ingevuld
						if (scope.werkgevers.length === 0) {
							//if omzetting, we put the aanvrager as only werkgever
							if (scope.gekozenType === "omzetting") {
								scope.werkgevers.push({
									naam: scope.aanvrager.naam,
									vestigingsplaats: scope.aanvrager.plaats,
								});
							} else {
								scope.werkgevers.push({
									naam: scope.aanvrager.naam,
									vestigingsplaats: scope.aanvrager.plaats,
								});
								_.each(scope.selectedPassen, function (pas) {
									Array.prototype.push.apply(scope.werkgevers, pas.werkgevers);
								});
							}
						} else {
							//werkgevers zijn al ingevuld dus doe er niks meer mee
							return;
						}
					});
					scope.selectPas = function (pas) {
						//als de pas al in de lijst zit
						if (_.includes(scope.selectedPassen, pas)) {
							//haal 'm eruit
							_.pull(scope.selectedPassen, pas);
						}
						//anders voeg hem toe
						else {
							if (pas.verlopen) {
								swal({
									title: "Verlopen pas",
									text: "De geselecteerde pas is verlopen en mag zonder uitzondering niet worden gebruikt voor een nieuwe aanstelling.",
									icon: "warning",

									buttons: {
										confirm: {
											text: "Begrepen",
											value: false,
											visible: true,
											className: "",
											closeModal: true,
										},
									},
									closeOnClickOutside: false,
									closeOnEsc: false,
								});
							}

							scope.selectedPassen.push(pas);
						}

						if (scope.subType === "verlenging" || scope.subType === "duplicaat") {
							_.each(pas.werkgevers, function (werkgever) {
								if (!_.some(scope.werkgevers, ["naam", werkgever.naam])) {
									scope.werkgevers.push(werkgever);
								}
							});
						}
					};

					scope.deselectAll = function () {
						_.forEach(scope.model.selectedLegacyPasId, function (value, key) {
							scope.model.selectedLegacyPasId[key] = false;
						});
						_.forEach(scope.model.selectedNewPasId, function (value, key) {
							scope.model.selectedNewPasId[key] = false;
						});
						scope.selectedPassen.length = 0;
						scope.werkgevers.length = 0;
					};
					scope.addWerkgever = function () {
						scope.werkgevers.push({
							naam: "",
							vestigingsplaats: "",
						});
					};
					scope.removeWerkgever = function (werkgever) {
						_.pull(scope.werkgevers, werkgever);
					};

					scope.search = function () {
						scope.searching = true;
						if (scope.query) {
							$resource(
								backendBaseUrl + "/approvable-pasaanvraag/vorigepassen/search/",
								{
									searchQuery: scope.query,
								},
								{
									query: {
										method: "GET",
										isArray: false,
										ignoreLoadingBar: true,
									},
								}
							)
								.query()
								.$promise.then(
									function success(result) {
										scope.legacyPassen = result;

										scope.searching = false;
									},
									function failure(result) {
										$log.error(result);

										scope.searching = false;
									}
								);
						} else {
							// this puts te original results back in the table if the searchquery is cleared
							scope.legacyPassen = scope.originalLegacyPassen;

							scope.searching = false;
						}
					};
				},
			};
		},
	])

	.directive("paskeuringCode95", [
		"backendBaseUrl",
		function (backendBaseUrl) {
			return {
				restrict: "E",
				scope: {
					onderdeel: "=",
					keuringAcknowledged: "=",
					keurstatusFinal: "=",
				},
				templateUrl: "app/modules/pasaanvraagkeuren/templates/code95.tpl.html",
				link: function (scope, element, attrs) {
					scope.onderdeel.url = backendBaseUrl + "/image/" + scope.onderdeel.url;
				},
			};
		},
	])

	.directive("paskeuringRijbewijs", [
		"backendBaseUrl",
		function (backendBaseUrl) {
			return {
				restrict: "E",
				scope: {
					onderdeel: "=",
					keuringAcknowledged: "=",
					keurstatusFinal: "=",
				},
				templateUrl: "app/modules/pasaanvraagkeuren/templates/rijbewijs.tpl.html",
				link: function (scope, element, attrs) {
					scope.onderdeel.url = backendBaseUrl + "/image/" + scope.onderdeel.url;
				},
			};
		},
	]);
