var module = angular
  .module("aanstellingspas.service.notificationservice", [])
  .factory("notificationservice", [
    "lrNotifier",
    "$sce",
    "$resource",
    "backendBaseUrl",
    "$rootScope",
    function (lrNotifier, $sce, $resource, backendBaseUrl, $rootScope) {
      var notificationServiceInstance = {};

      // for now, the only channel is notification.
      // later, this might become a variable if we need more channels
      var channel = lrNotifier("notification");

      /*
       * Spawns info message on dashboard with given string
       * Can contain HTML
       */
      notificationServiceInstance.info = function (message) {
        channel.info($sce.trustAsHtml(message));
      };

      /*
       * Spawns warn message on dashboard with given string
       * Can contain HTML
       */
      notificationServiceInstance.warn = function (message) {
        channel.warn($sce.trustAsHtml(message));
      };

      /*
       * Spawns error message on dashboard with given string
       * Can contain HTML
       */
      notificationServiceInstance.error = function (message) {
        channel.error($sce.trustAsHtml(message));
      };

      /*
       * Spawns success message on dashboard with given string
       * Can contain HTML
       */
      notificationServiceInstance.success = function (message) {
        channel.success($sce.trustAsHtml(message));
      };

      notificationServiceInstance.getBeoordelingenNotifications = function () {
        $resource(backendBaseUrl + "/beoordelingen")
          .query()
          .$promise.then(
            function success(result) {
              $rootScope.beoordelingenNotifications = _.filter(
                result,
                function (r) {
                  return !r.snoozed;
                }
              ).length;
            },
            function failure(result) {}
          );
      };

      notificationServiceInstance.getNotifications = function () {
        var roles = ["secretariaatpas", "admin"];
        if ($rootScope.isInRoles(roles)) {
          this.getBeoordelingenNotifications();
        }
      };

      return notificationServiceInstance;
    },
  ]);
