"use strict";

var module = angular.module("aanstellingspas.examenbloktoevoegen", [
	"aanstellingspas.service.bedrijfsservice",
	"rt.select2",
	"ngMessages",
	"validexamenblokdate",
]);

module.config(function ($routeProvider) {
	// bind a route to a view
	$routeProvider.when("/examenbloktoevoegen", {
		templateUrl: "app/modules/examenbloktoevoegen/examenbloktoevoegen.view.html",
		controller: "ExamenblokToevoegenController",
		title: "Examenblok toevoegen",
		reloadOnSearch: false,
	});
});

module.controller("ExamenblokToevoegenController", [
	"$scope",
	"$location",
	"backendBaseUrl",
	"$log",
	"$resource",
	"notificationservice",
	"$route",
	"$routeParams",
	function ($scope, $location, backendBaseUrl, $log, $resource, notificationservice, $route, $routeParams) {
		$scope.s2_gedelegeerdenplaats_options = {
			language: "nl",
			placeholder: "Selecteer een plaats...",
			formatResult: function (plaats) {
				if (plaats.obj.isVoorkeursPlaats) {
					return plaats.text;
				} else {
					return (
						'<span style="color: #c9ced3; font-style: italic;">' + plaats.text + " (niet opgegeven)</span>"
					);
				}
			},
		};

		$scope.regios = [];
		$scope.gedelegeerden = [];
		$scope.bedrijven = [];
		$scope.model = {};
		$scope.toewijzen = false;
		$scope.examensoorten = [
			{
				id: 0,
				naam: "Beroepsverkeersregelaars",
			},
			{
				id: 1,
				naam: "Transportbegeleiders",
			},
		];

		$scope.aantal = [
			{ id: "4", text: "4 (minimum)" },
			{ id: "5", text: "5" },
			{ id: "6", text: "6" },
			{ id: "7", text: "7" },
			{ id: "8", text: "8" },
		];
		$scope.model.aantalDeelnemers = 4;
		//set default examensoort to beroeps
		$scope.soortexamen = 0;

		$scope.backendBaseUrl = backendBaseUrl;

		//set default dagdeel
		$scope.model.dagdeel = "Middag";
		$scope.isSubmitting = false;

		$resource(backendBaseUrl + "/organisatie")
			.query()
			.$promise.then(
				function success(result) {
					$scope.bedrijven = result;
				},
				function failure(result) {
					if (result.status === 404) {
						notificationservice.info("Er zijn geen regios gevonden.");
						$location.path("/dashboard");
					} else {
						$log.error(result);
					}
				}
			);

		$resource(backendBaseUrl + "/gedelegeerde")
			.query({
				activeGedelegeerde: true,
			})
			.$promise.then(
				function success(result) {
					$scope.gedelegeerden = result;
					$scope.model.gedelegeerde = $routeParams.gedelegeerdeId;
				},
				function failure(result) {
					if (result.status === 404) {
						notificationservice.info("Er zijn geen gedelegeerden gevonden.");
						$location.path("/dashboard");
					} else {
						$log.error(result);
					}
				}
			);

		$scope.gedelegeerdeUpdated = function () {
			$resource(backendBaseUrl + "/gedelegeerde/:gedelegeerdeId/gemeentes")
				.query({
					gedelegeerdeId: $scope.model.gedelegeerde,
				})
				.$promise.then(function success(result) {
					$scope.plaatsen = result;
					$scope.model.plaats = null;
					$scope.model.plaatsId = null;
					$scope.kruispunten = null;
					$scope.model.kruispunt = null;
					$scope.model.kruispuntId = null;
				});
		};
		/*
		 *triggered when a plaats has been selected. Updates list of kruispunten.
		 */
		$scope.plaatsUpdated = function () {
			// check if we have a plaats id before doing the request
			if ($scope.model.plaatsId) {
				$resource(
					backendBaseUrl + "/plaats/:plaatsid/kruispunten",
					{
						plaatsid: $scope.model.plaatsId,
					},
					{}
				)
					.query()
					.$promise.then(function success(result) {
						$scope.kruispunten = result;
						$scope.kruispuntPlaceholder = "Selecteer een kruispunt...";

						$scope.model.kruispunt = null;
						$scope.model.kruispuntId = null;
					});
			}
		};
		$scope.getPlaceHolderNaam = function () {
			return $scope.kruispuntPlaceholder;
		};
		$scope.$watch("model.bedijfidforassignment", function (newValue) {
			var bedrijf = _.find($scope.bedrijven, function (o) {
				return o.id === newValue;
			});
			if (bedrijf !== null && bedrijf !== undefined) {
				$scope.bedrijfsnaam = bedrijf.naam;
			}
		});

		$scope.addExamenblok = function () {
			$scope.isSubmitting = true;
			// Check if gedelegeerde is available
			$resource(backendBaseUrl + "/gedelegeerdeavailabilitycheck", {})
				.get({
					datum: $scope.model.datum,
					gedelegeerdeId: $scope.model.gedelegeerde,
					currentExamenId: 0,
				})
				.$promise.then(function (result) {
					if (result.available === false) {
						swal({
							title: "Gedelegeerde niet beschikbaar",
							text: "De gedelegeerde is niet beschikbaar op deze datum",
							icon: "warning",

							buttons: {
								confirm: {
									text: "Ok",
									value: true,
									visible: true,
									className: "",
									closeModal: true,
								},
							},

							closeOnClickOutside: false,
							closeOnEsc: false,
						}).then(function () {
							$scope.isSubmitting = false;
							return;
						});
						$scope.isSubmitting = false;
						return;
					} else {
						//if we're not doing a toewijzing, make sure the bedrijfid is 0
						if ($scope.toewijzen === false) {
							$scope.model.bedijfidforassignment = 0;
							$scope.model.aantalDeelnemers = 0;
						}
						//are we adding a transport examen?
						if ($scope.soortexamen === 1) {
							$scope.model.transportbegeleidersexamen = true;
						}
						$resource(
							backendBaseUrl + "/examenblok",
							{},
							{
								post: { method: "POST" },
							}
						)
							.post({
								examenblokaddmodel: $scope.model,
							})
							.$promise.then(function success() {
								notificationservice.success("Examenblok opgeslagen");
								$route.updateParams({
									gedelegeerdeId: $scope.model.gedelegeerde,
								});
								$route.reload();
								$scope.isSubmitting = false;
							});
					}
				});
		};
	},
]);
