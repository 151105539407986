"use strict";

var module = angular.module("aanstellingspas.bestelling", [
  "aanstellingspas.service.bedrijfsservice",
  "rt.select2",
  "ngMessages",
]);

module.config(function ($routeProvider) {
  // bind a route to a view
  $routeProvider.when("/bestelling/:id", {
    templateUrl: "app/modules/bestelling/bestelling.view.html",
    controller: "BestellingController",
    title: "Bestelling",
    reloadOnSearch: false,
    navigation_id: "accountbeheer",
  });
});

module.controller("BestellingController", [
  "$scope",
  "authService",
  "$http",
  "$location",
  "backendBaseUrl",
  "$log",
  "$resource",
  "$localStorage",
  "notificationservice",
  "$route",
  "$filter",
  "Upload",
  "$routeParams",
  function (
    $scope,
    authService,
    $http,
    $location,
    backendBaseUrl,
    $log,
    $resource,
    $localStorage,
    notificationservice,
    $route,
    $filter,
    Upload,
    $routeParams
  ) {
    $scope.bestellingId = $routeParams.id;
    $scope.bestelling = {};
    $scope.factuur = {};
    $scope.factuurError = "";

    $resource(backendBaseUrl + "/bestelling/:id", { id: $scope.bestellingId })
      .get()
      .$promise.then(
        function success(result) {
          $scope.bestelling = result;
          $resource(backendBaseUrl + "/bestelling/:id/factuur", {
            id: $scope.bestellingId,
          })
            .get()
            .$promise.then(
              function success(factuurResult) {
                if (!angular.isDefined(factuurResult.id)) {
                  $scope.factuurError = "Geen factuur gevonden";
                } else {
                  $scope.factuur = factuurResult;
                }
              },
              function failure(factuurResult) {
                $scope.factuurError = "Er is iets misgegaan";
              }
            );
        },
        function failure(result) {
          $log.error(result);
        }
      );
  },
]);
