var module = angular
  .module("aanstellingspas.logout", [])
  .config(function ($routeProvider) {
    $routeProvider.when("/logout", {
      controller: "logoutController",
      templateUrl: "app/modules/logout/logout.view.html",
    });
  });

module.controller("logoutController", [
  "$scope",
  "$rootScope",
  "$location",
  "authService",
  "notificationservice",
  "$localStorage",
  function (
    $scope,
    $rootScope,
    $location,
    authService,
    notificationService,
    $localStorage
  ) {
    authService.logout();

    $rootScope.userproperties = null;
    delete $localStorage.userproperties;

    notificationService.info("U bent nu uitgelogd.");

    $location.path("/#/home");
  },
]);
