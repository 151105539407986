"use strict";

var module = angular.module("aanstellingspas.gemeentegoedkeuring", [
  "aanstellingspas.service.bedrijfsservice",
  "smart-table",
]);

module.config(function ($routeProvider) {
  $routeProvider.when("/gemeentegoedkeuring/:id", {
    templateUrl:
      "app/modules/gemeentegoedkeuring/gemeentegoedkeuring.view.html",
    controller: "KeurGemeenteAanvraagController",
    title: "Beoordeling bekijken",
    navigation_id: "gemeentegoedkeuring",
    reloadOnSearch: false,
  });

  $routeProvider.when("/gemeentegoedkeuring/", {
    templateUrl:
      "app/modules/gemeentegoedkeuring/listgemeentegoedkeuring.view.html",
    controller: "ListGemeenteAanvragen",
    title: "Beoordeling bekijken",
    navigation_id: "gemeentegoedkeuring",
    reloadOnSearch: false,
  });
});

module.controller("ListGemeenteAanvragen", [
  "$scope",
  "authService",
  "$http",
  "$location",
  "backendBaseUrl",
  "$log",
  "$resource",
  "$localStorage",
  "notificationservice",
  "$route",
  "$filter",
  "Upload",
  function (
    $scope,
    authService,
    $http,
    $location,
    backendBaseUrl,
    $log,
    $resource,
    $localStorage,
    notificationservice,
    $route,
    $filter,
    Upload
  ) {
    $resource(backendBaseUrl + "/gemeentegoedkeuring/approvable")
      .query()
      .$promise.then(
        function success(result) {
          $scope.gemeentegoedkeuring = result;
        },
        function failure(result) {
          $log.error(result);
        }
      );

    $scope.openKeuring = function (id) {
      $location.path("/gemeentegoedkeuring/" + id);
    };
  },
]);

module.controller("KeurGemeenteAanvraagController", [
  "$scope",
  "authService",
  "$http",
  "$location",
  "backendBaseUrl",
  "$log",
  "$resource",
  "$localStorage",
  "notificationservice",
  "$route",
  "$filter",
  "Upload",
  "$routeParams",
  "$rootScope",
  function (
    $scope,
    authService,
    $http,
    $location,
    backendBaseUrl,
    $log,
    $resource,
    $localStorage,
    notificationservice,
    $route,
    $filter,
    Upload,
    $routeParams,
    $rootScope
  ) {
    $scope.backendBaseUrl = backendBaseUrl;

    $resource(backendBaseUrl + "/gemeentegoedkeuring/" + $routeParams.id)
      .get()
      .$promise.then(
        function success(result) {
          $scope.goedkeuring = result;
          $scope.reedsGoedgekeurd = $scope.goedkeuring.goedgekeurdDoorSVNL;
        },
        function failure(result) {
          $log.error(result);
        }
      );

    /*
     * Aanvraag goedkeuren
     *
     */
    $scope.keurAanvraagGoed = function () {
      putGoedkeuring();
    };

    /*
     * Aanvraag afkeuren
     *
     */
    $scope.keurAanvraagAf = function () {
      putAfkeuring();
    };

    /*
     * Aanvraag verwijderen
     *
     */
    $scope.verwijderAanvraag = function () {
      deleteAanvraag();
    };

    $scope.domeinGoedgekeurd = function () {
      $scope.domeinapproved = true;
    };
    $scope.domeinAfgekeurd = function () {
      $scope.domeinapproved = false;
    };
    $scope.contactgegevensGoedgekeurd = function () {
      $scope.contactgegevensapproved = true;
    };
    $scope.contactgegevensAfgekeurd = function () {
      $scope.contactgegevensapproved = false;
    };

    $scope.goedkeurButtonDisabled = function () {
      if (
        $scope.contactgegevensapproved != undefined &&
        $scope.domeinapproved != undefined
      ) {
        if (
          $scope.contactgegevensapproved == true &&
          $scope.domeinapproved == true
        ) {
          //only return false (thus not disabling the button) if both checks are approved
          return false;
        }
      }
      // disable the button
      return true;
    };

    $scope.afkeurButtonDisabled = function () {
      if (
        $scope.contactgegevensapproved != undefined &&
        $scope.domeinapproved != undefined
      ) {
        if (
          $scope.contactgegevensapproved == false ||
          $scope.domeinapproved == false
        ) {
          //only return false (thus not disabling the button) if both checks are approved
          return false;
        }
      }
      // disable the button
      return true;
    };

    var putGoedkeuring = function () {
      // do the actual keuring
      $resource(
        backendBaseUrl + "/gemeentegoedkeuring/:id/approve",
        {
          id: $routeParams.id,
        },
        {
          put: { method: "PUT" },
        }
      )
        .put()
        .$promise.then(function success() {
          notificationservice.success("Gemeente goedgekeurd");
          $location.path("/dashboard");
        });
    };

    var putAfkeuring = function () {
      // do the actual keuring
      $resource(
        backendBaseUrl + "/gemeentegoedkeuring/:id/disapprove",
        {
          id: $routeParams.id,
        },
        {
          put: { method: "PUT" },
        }
      )
        .put()
        .$promise.then(function success() {
          notificationservice.success("Gemeente afgekeurd");
          $location.path("/dashboard");
        });
    };

    var deleteAanvraag = function () {
      // do the actual keuring
      $resource(
        backendBaseUrl + "/gemeentegoedkeuring/:id/",
        {
          id: $routeParams.id,
        },
        {
          put: { method: "DELETE" },
        }
      )
        .put()
        .$promise.then(function success() {
          notificationservice.success("Aanvraag verwijderd");
          $location.path("/dashboard");
        });
    };

    var gotoNextAanvraag = function () {
      $http({
        method: "GET",
        url: backendBaseUrl + "/approvable-gemeentegoedkeuring/",
      }).then(
        function success(result) {
          // this goes to detailview
          $location.path("/gemeentegoedkeuring/" + result.data.id);
        },
        function error(result) {
          if (result.status === 404) {
            // this goes to list view
            $location.path("/dashboard");
          } else {
            $log.error(result);
          }
        }
      );
    };
  },
]);
