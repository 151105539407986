"use strict";

var module = angular.module("aanstellingspas.aanvragen", ["smart-table"]);

module.config(function ($routeProvider) {
  $routeProvider.when("/aanvragen", {
    templateUrl: "app/modules/aanvragen/aanvragen.view.html",
    controller: "AanvragenController",
    title: "Aanvragen",
    navigation_id: "aanvragen",
  });
});

module.controller("AanvragenController", [
  "$scope",
  "$http",
  "$log",
  "$localStorage",
  "notificationservice",
  "$resource",
  "backendBaseUrl",
  "$location",
  function (
    $scope,
    $http,
    $log,
    $localStorage,
    notificationService,
    $resource,
    backendBaseUrl,
    $location
  ) {
    $scope.backendBaseUrl = backendBaseUrl;

    $scope.aanvragen = [];

    $scope.findAanvragen = function (aanvraag) {
      if ($scope.aanvragen.length <= 1) {
        $resource(backendBaseUrl + "/pasaanvragen/:query", { query: aanvraag })
          .query()
          .$promise.then(
            function success(result) {
              console.log($scope.aanvragen);
              $scope.aanvragen = result;
            },
            function failure(result) {
              $log.error(result);
            }
          );
      }
    };
  },
]);
