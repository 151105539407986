/*
 * Use the directive as follows:
 * <fileuploader	backendurl="{{backendBaseUrl}}/pasfoto/document"
 *  				output="uploadedPasfoto"
 *					max-file-size="20MB"
 *					allowed-file-types="'.pdf,image/*'">
 *		You can add some custom content here, it will be shown under the file select button
 * </fileuploader>
 *
 * where output contains the name of the scope property that will contain the uploaded
 * file information after upload succesfully completes
 *
 * example: output="kaas"
 *
 * then $scope.kaas will be set on succesful upload (see limitations)
 *
 * Limitations:
 * - Currently only supports single files, can be extended to support multiple files
 * - The scope property as defined in 'output' only lives in the view scope, not in
 *   the controller scope. You will need to pass it to the controller via e.g.
 *        <button ng-click="sendToController(kaas)" />
 *   You can then use it there, or set it to the controller scope.
 */

var module = angular
  .module("aanstellingspas.fileuploader", [])
  .directive("fileuploader", [
    "Upload",
    "$timeout",
    "backendBaseUrl",
    function (Upload, $timeout, backendBaseUrl) {
      return {
        // http://stackoverflow.com/a/21714314/6077862
        restrict: "E",
        transclude: true,
        scope: {
          backendurl: "@",
          output: "@",
          formName: "@",
          allowedFileTypes: "@",
          maxFileSize: "@",
          buttonTextLarge: "@",
          buttonTextMobile: "@",
        },
        templateUrl:
          "app/modules/directives/fileuploader/fileuploader.view.html",
        link: function (scope, element, attrs) {
          // clears all ngf form errors
          scope.clearErrors = function () {
            $timeout(function () {
              // delete selectfile error msgs
              scope.errorFile = {};
            });

            _.set(scope, scope.output, null);
          };

          /**
           * Does the actual uploading to the backend.
           * Gets called when a file is present in the input type
           */
          scope.uploadFiles = function (file) {
            scope.file = file;

            if (file) {
              file.upload = Upload.upload({
                url: scope.backendurl,
                method: "POST",
                data: {
                  file: file,
                },
              });

              file.upload.then(
                function (response) {
                  // appending fileName original users' file name in array for use in view
                  file.fileName = response.data.filename;
                  file.originalFilename = scope.file.name;
                  file.url = backendBaseUrl + response.data.url;
                  // setting isPdf boolean if the filename contains a .pdf extension
                  var extension = file.fileName.substr(
                    file.fileName.lastIndexOf(".") + 1
                  );
                  if (extension === "pdf") {
                    file.isPdf = true;
                  } else {
                    file.isPdf = false;
                  }

                  // save the output to the scope
                  //scope.$parent[scope.output] = file;
                  _.set(scope, scope.output, file);
                },
                function (response) {
                  if (response.status !== 200) {
                    scope.bvoBestand = null;
                    if (response.status === 413) {
                      swal({
                        title: "Fout bij uploaden",
                        text: "Het door u gekozen bestand is te groot. Kies alstublieft een kleiner bestand.",
                        icon: "error",
                      });
                    }
                    if (response.status === 415) {
                      swal({
                        title: "Fout bij uploaden",
                        text: "Het door u gekozen bestand wordt niet ondersteund. Kies alstublieft een fotobestand of pdf.",
                        icon: "error",
                      });
                    }
                  }
                },
                function (evt) {
                  // Math.min is to fix IE which reports 200% sometimes
                  file.progress = Math.min(
                    100,
                    parseInt((100.0 * evt.loaded) / evt.total)
                  );
                }
              );
            }
          };
        },
      };
    },
  ]);
