"use strict";

var module = angular.module("aanstellingspas.pascreator", []);

module.config(function ($routeProvider) {
	$routeProvider.when("/pascreator", {
		templateUrl: "app/modules/pascreator/pascreator.view.html",
		controller: "PasCreatorController",
		title: "Pas aanmaken",
		navigation_id: "passen",
	});
});

module.controller("PasCreatorController", [
	"$scope",
	"$http",
	"$log",
	"$localStorage",
	"notificationservice",
	"$resource",
	"backendBaseUrl",
	"$location",
	function ($scope, $http, $log, $localStorage, notificationService, $resource, backendBaseUrl, $location) {
		$scope.model = {};
		$scope.model.pasOrganisaties = [];
		$scope.model.pasOrganisaties.push({ naam: "", vestigingsplaats: "" });
		$scope.disabled = false;
		$scope.soort = "beroeps";
		$scope.organisaties = [];
		$scope.selectedOrganisatie;
		$scope.selectedOrganisatieIsRijkswaterstaat;
		$scope.backendBaseUrl = backendBaseUrl;
		var vandaag = new Date();
		$scope.vandaag = vandaag.getDate() + "-" + (vandaag.getMonth() + 1) + "-" + vandaag.getFullYear();
		$scope.overvijfjaar = vandaag.getDate() + "-" + (vandaag.getMonth() + 1) + "-" + (vandaag.getFullYear() + 5);

		$scope.addWerkgever = function () {
			$scope.model.pasOrganisaties.push({ naam: "", vestigingsplaats: "" });
		};
		$scope.removeWerkgever = function (werkgever) {
			_.pull($scope.model.pasOrganisaties, werkgever);
		};
		$scope.updateSelectedOrganisatie = function () {
			$scope.selectedOrganisatieIsRijkswaterstaat = false;
			var selectedorganisatieInstance = _.find($scope.organisaties, function (i) {
				return i.id === $scope.selectedOrganisatie;
			});
			console.log("selected: " + selectedorganisatieInstance.naam);
			if (selectedorganisatieInstance) {
				if (selectedorganisatieInstance.naam.includes("Rijkswaterstaat")) {
					$scope.selectedOrganisatieIsRijkswaterstaat = true;
					$scope.model.gratis = false;
				}
			}
		};
		$scope.savePas = function () {
			$scope.model.geldigTot = convertDate($scope.model.geldigTot);
			$scope.model.geboren = convertDate($scope.model.geboren);
			$scope.model.afgifteDatum = convertDate($scope.model.afgifteDatum);
			$scope.disabled = true;
			$scope.model.pasfotoFilename = $scope.pasfoto.fileName;
			$resource(backendBaseUrl + "/secretariaatpas/:soort/:assignorganisatie", {
				soort: $scope.soort,
				assignorganisatie: $scope.toewijzen ? $scope.selectedOrganisatie : "",
			})
				.save($scope.model)
				.$promise.then(
					function success() {
						notificationService.success("Pas opgeslagen");
						$scope.disabled = false;
						$scope.model = {};
						$scope.pasfoto = null;
						$scope.selectedOrganisatie = null;
						$scope.gratis = false;
						$scope.model.pasOrganisaties = [];
						$scope.model.pasOrganisaties.push({
							naam: "",
							vestigingsplaats: "",
						});

						$scope.pasForm.$setPristine();
					},
					function failure(result) {
						$scope.disabled = false;
						$scope.model.geldigTot = convertDateReverse($scope.model.geldigTot);
						$scope.model.geboren = convertDateReverse($scope.model.geboren);
						$scope.model.afgifteDatum = convertDateReverse($scope.model.afgifteDatum);

						// this volgnummer is already in use
						if (result.status === 409) {
							swal({
								title: "Volgnummer niet beschikbaar",
								text: "Dit volgnummer is al in gebruik",
								icon: "error",

								buttons: {
									confirm: {
										text: "Ok",
										value: true,
										visible: true,
										className: "",
										closeModal: true,
									},
								},

								closeOnClickOutside: false,
								closeOnEsc: false,
							}).then(function () {
								return;
							});
						} else {
							notificationService.error("Mislukt :(");
						}
					}
				);
		};
		var convertDateReverse = function (isoDate) {
			var date = new Date(isoDate);
			return date.getDate() + "-" + date.getMonth() + "-" + date.getFullYear();
		};
		var convertDate = function (dateString) {
			var parts = dateString.split("-");
			var date = new Date(parts[2], parts[1] - 1, parts[0]);
			return date.toISOString();
		};

		$resource(backendBaseUrl + "/organisatie")
			.query()
			.$promise.then(
				function success(result) {
					$scope.organisaties = result;
				},
				function failure(result) {
					if (result.status === 404) {
						notificationservice.info("Er zijn geen bedrijven gevonden.");
					} else {
						$log.error(result);
					}
				}
			);
		$scope.$watch("soort", function (newValue, oldValue) {
			if (newValue === "transport") {
				$scope.model.bevoegdheid = "Transportbegeleider";
				$scope.model.beperking = "Transportbegeleider";
			}
		});
	},
]);
