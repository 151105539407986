"use strict";

var module = angular.module("aanstellingspas.examen", [
	"aanstellingspas.service.bedrijfsservice",
	"rt.select2",
	"ngMessages",
	"validexamenblokdate",
	"validbirthdate",
]);

module.config(function ($routeProvider) {
	// bind a route to a view
	$routeProvider.when("/examen/:id", {
		templateUrl: "app/modules/examen/examen.view.html",
		controller: "ExamenblokController",
		title: "Examenblok",
		reloadOnSearch: false,
	});
});

module.controller("ExamenblokController", [
	"$scope",
	"authService",
	"$http",
	"$location",
	"backendBaseUrl",
	"$log",
	"$resource",
	"$localStorage",
	"notificationservice",
	"$route",
	"$filter",
	"Upload",
	"$routeParams",
	function (
		$scope,
		authService,
		$http,
		$location,
		backendBaseUrl,
		$log,
		$resource,
		$localStorage,
		notificationservice,
		$route,
		$filter,
		Upload,
		$routeParams
	) {
		$scope.examenblokid = $routeParams.id;

		$scope.regios = [];
		$scope.gedelegeerden = [];
		$scope.plaatsen = [];
		$scope.kruispunten = [];
		$scope.examenModel = {};
		$scope.dagdelen = [];
		$scope.verwijderde_persoonsgegevens = false;

		$scope.backendBaseUrl = backendBaseUrl;
		$scope.deelnemerStatussen = [
			{ value: "InAfwachting", text: "In afwachting" },
			{ value: "NietGeslaagd", text: "Niet geslaagd" },
			{ value: "Geslaagd", text: "Geslaagd" },
			{ value: "NietAanwezig", text: "Niet aanwezig" },
		];

		// Do some formatting on select2 options
		$scope.s2_gedelegeerde_options = {
			language: "nl",
			placeholder: "Selecteer een gedelegeerde",
			formatSelection: function (gedelegeerde) {
				var gedelegeerdeEmailAdres = _.find($scope.gedelegeerden, function (o) {
					return o.id == $scope.examenModel.gedelegeerde;
				});

				return gedelegeerde.text + " &lt;" + gedelegeerdeEmailAdres.emailadres + "&gt;";
			},
			allowClear: false,
		};

		$scope.s2_gedelegeerdenplaats_options = {
			language: "nl",
			placeholder: "Selecteer een plaats...",
			formatResult: function (plaats) {
				if (plaats.obj.isVoorkeursPlaats) {
					return plaats.text;
				} else {
					return (
						'<span style="color: #c9ced3; font-style: italic;">' + plaats.text + " (niet opgegeven)</span>"
					);
				}
			},
		};

		// get examenblok
		$resource(backendBaseUrl + "/secretariaatexamen/" + $scope.examenblokid)
			.get()
			.$promise.then(
				function success(examenBlokResult) {
					$scope.examenModel = examenBlokResult;

					$scope.examenModel.datum = examenBlokResult.datum;
					$scope.examenModel.gedelegeerde = examenBlokResult.gedelegeerde.id;

					$scope.oldGedelegeerde = examenBlokResult.gedelegeerde;

					$scope.examenModel.dagdeel = examenBlokResult.dagdeel.dagdeel;

					// If the blok is sold, we can set the values of
					// plaats and kruispunt dropdown
					if ($scope.examenModel.verkocht) {
						if ($scope.examenModel.type !== "Transportbegeleiders examenblok") {
							getPlaatsenForGedelegeerde();
							$scope.examenModel.plaats = examenBlokResult.plaats.id;
							$scope.examenModel.kruispunt = examenBlokResult.kruispunt.id;
						}

						// if the deelnemer status is anything else than InAfwachting, it cannot be changed
						_.forEach($scope.examenModel.deelnemers, function (deelnemer) {
							if (deelnemer.deelnemerStatus === "InAfwachting") {
								//this status is not permanent
							} else {
								//anything else is
								deelnemer.statusPermanent = true;
							}
						});
						// Checks if there are deleted persoonsgegevens
						_.forEach($scope.examenModel.deelnemers, function (deelnemer) {
							if (deelnemer.isDeleted) {
								$scope.verwijderde_persoonsgegevens = true;
							}
						});
					}
				},
				function failure(result) {
					if (result.status === 404) {
						notificationservice.info("Examenblok niet gevonden");
						$location.path("/dashboard");
					} else {
						$log.error(result);
					}
				}
			);
		$resource(backendBaseUrl + "/gedelegeerde")
			.query()
			.$promise.then(
				function success(result) {
					$scope.gedelegeerden = result;
				},
				function failure(result) {
					if (result.status === 404) {
						notificationservice.info("Er zijn geen gedelegeerden gevonden.");
						$location.path("/dashboard");
					} else {
						$log.error(result);
					}
				}
			);

		$resource(backendBaseUrl + "/dagdeel")
			.query()
			.$promise.then(
				function success(result) {
					$scope.dagdelen = result;
				},
				function failure(result) {
					if (result.status === 404) {
						notificationservice.info("Er zijn geen dagdelen gevonden.");
						$location.path("/dashboard");
					} else {
						$log.error(result);
					}
				}
			);

		/*
		 * triggered when a plaats has been selected. Updates list of kruispunten.
		 */
		$scope.plaatsUpdated = function () {
			getKruispuntenForPlaats();
			// Set kruispunt to 0, so no kruispunt in the dropdown is selected
			$scope.examenModel.kruispunt = 0;
			console.log("plaats updated");
		};

		/*
		 * triggered when a plaats has been selected. Updates list of kruispunten.
		 */
		$scope.gedelegeerdeUpdated = function () {
			getPlaatsenForGedelegeerde();
			console.log("gedelegeerde updated");
		};

		var getKruispuntenForPlaats = function () {
			$resource(
				backendBaseUrl + "/secretariaatplaats/:plaatsid/kruispunten",
				{
					plaatsid: $scope.examenModel.plaats,
				},
				{}
			)
				.query()
				.$promise.then(function success(result) {
					$scope.kruispunten = result;
					$scope.kruispuntPlaceholder = "Selecteer een kruispunt...";
				});
		};

		var getPlaatsenForGedelegeerde = function () {
			$resource(backendBaseUrl + "/gedelegeerde/" + $scope.examenModel.gedelegeerde + "/gemeentes")
				.query()
				.$promise.then(
					function success(result) {
						$scope.plaatsen = result;

						// get all kruispunten for dropdown
						getKruispuntenForPlaats();
					},
					function failure(result) {
						if (result.status === 404) {
							notificationservice.info("Er zijn geen plaatsen gevonden.");
							$location.path("/dashboard");
						} else {
							$log.error(result);
						}
					}
				);
		};

		/*
		 * Triggers a swal message, where the user can press confirm or cancel.
		 * If confirmed, sends a delete request to the backend, to remove current examenblok
		 * If cancelled, does nothing.
		 */
		$scope.delete = function () {
			swal({
				title: "Verwijderen",
				text: "Wilt u dit examenblok verwijderen?",
				icon: "info",

				buttons: {
					confirm: {
						text: "Ja",
						value: true,
						visible: true,
						className: "",
						closeModal: true,
					},
					cancel: {
						text: "Nee",
						value: false,
						visible: true,
						className: "",
						closeModal: true,
					},
				},
			}).then(
				function confirmAction(confirmed) {
					if (confirmed == true) {
						$resource(backendBaseUrl + "/examenblok/" + $scope.examenModel.id, {})
							.delete({})
							.$promise.then(function (result) {
								notificationservice.success("Examenblok verwijderd");
								$location.path("/examens");
							});
					}
				},
				function cancelAction() {}
			);
		};

		/*
		 * Does the actual submitting of the data to the server,
		 * after checking if gedeleerde and date is available
		 */

		$scope.updateExamenblok = function (sendEmail) {
			// Check if gedelegeerde is available
			$resource(backendBaseUrl + "/gedelegeerdeavailabilitycheck", {})
				.get({
					datum: $scope.examenModel.datum,
					gedelegeerdeId: $scope.examenModel.gedelegeerde,
					currentExamenId: $scope.examenModel.id,
				})
				.$promise.then(function (result) {
					if (result.available == false) {
						swal({
							title: "Gedelegeerde niet beschikbaar",
							text: "De gedelegeerde is niet beschikbaar op deze datum",
							icon: "warning",

							buttons: {
								confirm: {
									text: "Ok",
									value: true,
									visible: true,
									className: "",
									closeModal: true,
								},
							},

							closeOnClickOutside: false,
							closeOnEsc: false,
						}).then(function () {
							return;
						});
						return;
					} else {
						var request = $resource(
							backendBaseUrl + "/secretariaatexamen",
							{},
							{
								saveData: {
									method: "PUT",
									isArray: false,
								},
							}
						);

						// create a copy to prevent the view from showing weird data
						var model = angular.copy($scope.examenModel, model);

						model.gedelegeerde = _.find($scope.gedelegeerden, function (o) {
							return o.id == $scope.examenModel.gedelegeerde;
						});
						model.dagdeel = _.find($scope.dagdelen, function (o) {
							return o.dagdeel == $scope.examenModel.dagdeel;
						});

						// If the examenblok is verkocht, we also need to get the kruispunt and plaats
						if ($scope.examenModel.verkocht) {
							model.kruispunt = _.find($scope.kruispunten, function (o) {
								return o.id == $scope.examenModel.kruispunt;
							});

							model.plaats = _.find($scope.plaatsen, function (o) {
								return o.id == $scope.examenModel.plaats;
							});
						} else {
							model.plaats = null;
							model.kruispunt = null;
						}

						model.datum = $scope.examenModel.datum;

						request.saveData({}, model).$promise.then(function success() {
							if (sendEmail == true) {
								$resource(
									backendBaseUrl +
										"/secretariaatexamen/" +
										$scope.examenblokid +
										"/notifygedelegeerdenaboutchange"
								)
									.save({
										oldGedelegeerdeId: $scope.oldGedelegeerde,
									})
									.$promise.then(
										function succes() {
											notificationservice.success("E-mail verzonden");
											$route.reload();
										},
										function error() {
											notificationservice.error(
												"Er is iets mis gegaan. Probeert u het later nogmaals"
											);
											$route.reload();
										}
									);
							} else {
								notificationservice.success("Examenblok geupdate");
								$route.reload();
							}
						});
						$scope.closeNotifyGedelegeerdenModal();
					}
				});
		};

		$scope.openNotifyGedelegeerdenModal = function () {
			// Check of gedelegeerde is gewijzigd. Zoja, open modal.
			if ($scope.oldGedelegeerde != $scope.examenModel.gedelegeerde) {
				$scope.showNotifyGedelegeerdenModal = true;
			} else {
				$scope.updateExamenblok(false);
			}
		};

		$scope.closeNotifyGedelegeerdenModal = function () {
			$scope.showNotifyGedelegeerdenModal = false;
		};

		$scope.addDeelnemer = function () {
			if ($scope.examenModel.deelnemers.length < 8) {
				$scope.examenModel.deelnemers.push({});
			}
		};

		/*
		 * Gives you a friendlier name for the knows deelnemer stati
		 */
		$scope.statusToFriendlyName = function (status) {
			var statusObject = _.find($scope.deelnemerStatussen, ["value", status]);
			if (statusObject !== false) {
				return statusObject.text;
			}

			return status;
		};

		/*
		 * reloads the view
		 */
		$scope.reloadView = function () {
			$route.reload();
			$log.debug("reloading the view, you will never see this message");
		};

		$scope.copySuccess = function () {
			notificationservice.success("Naar klembord gekopiëerd");
		};
	},
]);
