"use strict";

var module = angular.module("aanstellingspas.plaatsen", ["smart-table"]);

module.config(function ($routeProvider) {
  $routeProvider.when("/plaatsen", {
    templateUrl: "app/modules/plaatsen/plaatsen.view.html",
    controller: "PlaatsenListController",
    title: "Plaatsen",
    navigation_id: "plaatsen",
  });
});

module.controller("PlaatsenListController", [
  "$scope",
  "$http",
  "$log",
  "$localStorage",
  "notificationservice",
  "$resource",
  "backendBaseUrl",
  "$location",
  function (
    $scope,
    $http,
    $log,
    $localStorage,
    notificationService,
    $resource,
    backendBaseUrl,
    $location
  ) {
    //collection of all gedelegeerden from backend
    $scope.collection = [];

    //collection of gedelegeerden displayed in view
    $scope.displayed = [];

    $resource(backendBaseUrl + "/secretariaatplaats/list")
      .query()
      .$promise.then(
        function success(result) {
          $scope.collection = result;
        },
        function failure(result) {
          $log.error(result);
        }
      );
  },
]);
