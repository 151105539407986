var module = angular
  .module("aanstellingspas.snoozeInfo", [])
  .directive("snoozeInfo", [
    "$rootScope",
    "backendBaseUrl",
    "$routeParams",
    "$log",
    "$resource",
    "notificationservice",
    "$route",
    function (
      $rootScope,
      backendBaseUrl,
      $routeParams,
      $log,
      $resource,
      notificationservice,
      $route
    ) {
      return {
        // http://stackoverflow.com/a/21714314/6077862
        restrict: "E",
        transclude: true,
        templateUrl: "app/modules/directives/snooze-info/snooze-info.view.html",
        scope: {
          comments: "=",
          snoozed: "=",
          snoozedBy: "@",
          snoozedAt: "@",
          snoozeComment: "@",
          openMessageModal: "&",
        },
        link: function (scope, element, attrs) {},
      };
    },
  ]);
