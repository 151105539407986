"use strict";

var module = angular.module("aanstellingspas.passen", ["smart-table"]);

module.config(function ($routeProvider) {
	$routeProvider
		.when("/pas", {
			templateUrl: "app/modules/passen/passen.view.html",
			controller: "PassenController",
			title: "Passen",
			navigation_id: "passen",
			reloadOnSearch: false,
		})
		.when("/pas/:pasnummer", {});
});

module.controller("PassenController", [
	"$scope",
	"$http",
	"$log",
	"$localStorage",
	"notificationservice",
	"$resource",
	"backendBaseUrl",
	"$location",
	"$routeParams",
	"$filter",
	function (
		$scope,
		$http,
		$log,
		$localStorage,
		notificationService,
		$resource,
		backendBaseUrl,
		$location,
		$routeParams,
		$filter
	) {
		$scope.backendBaseUrl = backendBaseUrl;

		//collection of all gedelegeerden from backend
		$scope.collection = [];

		//collection of gedelegeerden displayed in view
		$scope.displayed = [];

		$scope.pasnummer = null;
		$scope.aanstellingsnummer = null;
		$scope.naam = null;
		$scope.geboortedatum = null;
		$scope.bedrijfsnaam = null;
		$scope.kvk = null;
		$scope.searching = false;
		$scope.showAfkeurModal = false;
		$scope.showshowvrijstellingToewijzenModal = false;
		$scope.pasForVrijstelling = {};
		$scope.bedrijfForVrijstelling = null;
		$scope.bedrijven = [];

		$scope.radioButton = {};
		$scope.selectedPas = {};
		$scope.customReason = "";
		$scope.reasonPolice = "";

		$scope.afkeurredenen = [
			{ value: "Uit dienst", text: "Uit dienst" },
			{ value: "Extra werkgever", text: "Extra werkgever" },
			{ value: "Foute gegevens", text: "Foute gegevens" },
			{ value: "anders", text: "Anders, namelijk: " },
		];

		$scope.rangeResults = 12;
		$scope.selectedPassen = [];

		//adds and removes checked passen
		$scope.checkboxSelect = function (pas) {
			$scope.selectedPas = pas;
			if (pas.selected) {
				pas.selected = false;
				var index;
				for (var i = 0; i < $scope.selectedPassen.length; i++) {
					if ($scope.selectedPassen[i].id == pas.id) {
						index = i;
					}
				}
				$scope.selectedPassen.splice(index, 1);
				$scope.allChecked = false;
			} else {
				var alreadyInList = false;
				pas.selected = true;
				$scope.selectedPassen.forEach(function (selectedPas) {
					if (selectedPas.id == pas.id) {
						alreadyInList = true;
					}
				});
				if (!alreadyInList) {
					$scope.selectedPassen.push(pas);
					$scope.selectedPassen = $filter("orderBy")($scope.selectedPassen, "pasnummer", true);
				}
			}
		};

		$scope.checkboxSelectAll = function () {
			$scope.displayed.forEach(function (pas) {
				if ($scope.allChecked) {
					if (!pas.selected) {
						$scope.checkboxSelect(pas);
						pas.selected = true;
					}
				} else if (pas.selected) {
					$scope.checkboxSelect(pas);
					pas.selected = false;
				}
			});
		};

		//empty to export list. empty checkboxes
		$scope.emptySelection = function () {
			$scope.selectedPassen.forEach(function (pas) {
				var index = $scope.collection.indexOf(pas);
				$scope.collection[index].selected = false;
			});
			$scope.selectedPassen = [];
			$scope.allChecked = false;
			$scope.allSelection = false;
		};

		//Creates a pdf from $scope.selectedPassen
		$scope.createPdf = function () {
			var pdf = new jsPDF("l", "pt", "a4");
			pdf.setFontSize(22);
			var dateNow = new Date();
			pdf.text(20, 20, "Passenoverzicht");
			pdf.setFontSize(18);
			pdf.text(20, 35, dateNow.toLocaleString());
			var header = ["pasnummer", "naam", "geboortedatum", "pas_geldig_tot", "bedrijfsnaam"];
			var rows = [];

			$scope.selectedPassen.forEach(function (pas) {
				var datum = new Date(pas.geboortedatum);
				var geldigTot = new Date(pas.geldigTot);
				var bedrijfsnaam = "";
				if (pas.werkgevers) {
					pas.werkgevers.forEach(function (bedrijf) {
						bedrijfsnaam = bedrijfsnaam + bedrijf.naam + "\n";
					});
				}
				var body = [
					pas.pasnummer,
					pas.naam,
					datum.toLocaleDateString(),
					geldigTot.toLocaleDateString(),
					bedrijfsnaam,
				];
				rows.push(body);
			});
			pdf.autoTable(header, rows);
			pdf.save("Passenoverzicht_" + dateNow.toISOString() + ".pdf");
		};

		$scope.search = function (selectedPasId) {
			// if ($scope.pasnummer !== $routeParams.pasnummer) {
			// 	$location.search("pasnummer", null);
			// }
			$scope.searching = true;

			var geboortedatum;
			if ($scope.geboortedatum) {
				geboortedatum = $scope.geboortedatum.split("-").reverse().join("-");
			}

			$resource(backendBaseUrl + "/secretariaatpas/search")
				.query({
					naam: $scope.naam ? $scope.naam : null,
					pasnummer: $scope.pasnummer ? $scope.pasnummer : null,
					aanstellingsnummer: $scope.aanstellingsnummer ? $scope.aanstellingsnummer : null,
					geboorteDatum: geboortedatum ? geboortedatum : null,
					bedrijfsNaam: $scope.bedrijfsnaam ? $scope.bedrijfsnaam : null,
					kvk: $scope.kvk ? $scope.kvk : null,
				})
				.$promise.then(
					function success(result) {
						$scope.collection = result;
						$scope.searching = false;

						if (selectedPasId) {
							$scope.collection.forEach(function (obj) {
								if (obj.id === selectedPasId) {
									$scope.click(obj);
								}
							});
						}
					},
					function failure(result) {
						$log.error(result);

						$scope.searching = false;
					}
				);
		};

		$scope.click = function (pas, clicked) {
			$scope.selectedPas = pas;

			if (pas.displayed == true) {
				$scope.selectedPas = {};
				$scope.radioButton = {};
				pas.displayed = false;
				return;
			}
			pas.loading = true;

			$resource(backendBaseUrl + "/secretariaatpas/:id")
				.get({
					id: pas.id,
					isLegacyPas: pas.isLegacyPas,
				})
				.$promise.then(
					function success(result) {
						pas.extraInfo = result;
						pas.loading = false;
						pas.displayed = true;
					},
					function failure(result) {
						$log.error(result);
						pas.loading = false;
					}
				);
		};

		$scope.setOngeldig = function (pas) {
			$scope.showOngeldigMakenModal = true;
		};

		$scope.vrijstellingToewijzen = function (pas) {
			$scope.getBedrijven();
			$scope.pasForVrijstelling = pas;
			$scope.showvrijstellingToewijzenModal = true;
		};

		$scope.duplicaatPas = function (pasId) {
			$resource(backendBaseUrl + "/secretariaatpas/duplicate/" + pasId)
				.save({})
				.$promise.then(
					function success() {
						notificationService.success("Pas is gedupliceerd");
					},
					function failure() {
						notificationService.error("Pas dupliceren is niet gelukt");
					}
				);
		};

		$scope.getBedrijven = function () {
			$resource(backendBaseUrl + "/bedrijf")
				.query()
				.$promise.then(
					function success(result) {
						$scope.bedrijven = result;
					},
					function failure(result) {
						if (result.status === 404) {
						} else {
							$log.error(result);
						}
					}
				);
		};

		$scope.closeOngeldigModal = function () {
			$scope.showOngeldigMakenModal = false;
			clearModalInfo();
		};

		$scope.closeVrijstellingModal = function () {
			$scope.showvrijstellingToewijzenModal = false;
			clearVrijstellingModalInfo();
		};

		$scope.submit = function () {
			var selectedPasId = $scope.selectedPas.id;

			var reden = $scope.radioButton.selected;

			if ($scope.radioButton.selected == "anders") {
				reden = $scope.customReason;
			}

			$resource(backendBaseUrl + "/secretariaatpas/setongeldig/" + $scope.selectedPas.id)
				.save({
					PasId: $scope.selectedPas.id,
					Reden: reden,
					LegacyPas: $scope.selectedPas.isLegacyPas,
					RedenPolitie: $scope.reasonPolice,
				})
				.$promise.then(
					function success() {
						$scope.closeOngeldigModal();
						$scope.search(selectedPasId);
					},
					function failure(result) {
						$scope.closeOngeldigModal();
					}
				);
		};

		$scope.submitVrijstelling = function () {
			$resource(backendBaseUrl + "/secretariaatvrijstelling")
				.save({
					PasId: $scope.pasForVrijstelling.isLegacyPas ? 0 : $scope.pasForVrijstelling.id,
					LegacyPasId: $scope.pasForVrijstelling.isLegacyPas ? $scope.pasForVrijstelling.id : 0,
					OrganisatieId: $scope.bedrijfForVrijstelling,
				})
				.$promise.then(
					function success() {
						$scope.closeVrijstellingModal();
						notificationService.success("Vrijstelling toegewezen");
					},
					function failure(result) {
						$scope.closeVrijstellingModal();
						notificationService.error("Pas opnieuw printen niet gelukt");
					}
				);
		};

		var clearModalInfo = function () {
			$scope.selectedPas = {};
			$scope.radioButton = {};
			// $scope.customReason = "";
		};

		var clearVrijstellingModalInfo = function () {
			$scope.bedrijfForVrijstelling = {};
			$scope.pas = {};
		};

		if ($routeParams.pasnummer) {
			$scope.pasnummer = $routeParams.pasnummer;
			$scope.search();
		} else {
			//get alle non-legacy passen on load
			$resource(backendBaseUrl + "/secretariaatpas/")
				.query()
				.$promise.then(
					function success(result) {
						$scope.collection = result;
					},
					function failure(result) {
						$log.error(result);

						$scope.searching = false;
					}
				);
		}
	},
]);
