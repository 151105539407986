"use strict";

var module = angular
	.module("aanstellingspas.service.authinterceptorservice", [])
	.factory("authInterceptorService", function ($q, $location, $localStorage, $log, $rootScope) {
		var authInterceptorServiceFactory = {};

		var _request = function (config) {
			config.headers = config.headers || {};

			// check if an authticket is already set
			if ($localStorage.authticket) {
				var url = new URI(config.url);

				var hostname = url.hostname();

				if (_.includes(window.__env.allowedAuthTokenDomains, hostname)) {
					$log.debug("request to recognized backend location, including bearer token");
					config.headers.Authorization = "Bearer " + $localStorage.authticket.token;
				} else {
					$log.debug(
						"request to unrecognized backend hostname, not including bearer token in request, url: " + url
					);
				}
			}

			return config;
		};

		var _responseError = function (rejection) {
			if (rejection.status === 401) {
				$rootScope.loggedin = false;
				$location.path("/login");
			}
			return $q.reject(rejection);
		};

		authInterceptorServiceFactory.request = _request;
		authInterceptorServiceFactory.responseError = _responseError;

		return authInterceptorServiceFactory;
	});
