angular.module("validexamenblokdate", ["ngMessages"]);
angular
  .module("validexamenblokdate")
  .directive("validexamenblokdate", function () {
    return {
      restrict: "A",
      require: "ngModel",
      link: function (scope, element, attr, ctrl) {
        function customValidator(ngModelValue) {
          ctrl.$setValidity("validexamenblokdateformat", false);
          ctrl.$setValidity("plausibleexamenblokdate", false);

          // check if a valid date format was provided
          if (/^[0-9]{1,2}-[0-9]{1,2}-[0-9]{4}$/.test(ngModelValue)) {
            ctrl.$setValidity("validexamenblokdateformat", true);

            // http://stackoverflow.com/a/2587398/6077862
            var parts = ngModelValue.split("-");

            var year = parts[2];
            var month = parts[1];
            var day = parts[0];

            if (month > 12 || month < 1 || day > 31 || day < 1) {
              ctrl.$setValidity("validexamenblokdateformat", false);

              return ngModelValue;
            }

            var date = new Date(year, month - 1, day);

            if (date > new Date(2017, 0, 0)) {
              ctrl.$setValidity("plausibleexamenblokdate", true);
            }

            var dateEighteenYearsAgo = new Date();
            dateEighteenYearsAgo.setFullYear(
              dateEighteenYearsAgo.getFullYear() - 18
            );
          }

          return ngModelValue;
        }
        ctrl.$parsers.push(customValidator);
      },
    };
  });
