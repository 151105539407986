"use strict";

var module = angular
  .module("aanstellingspas.examenkandidaten", ["smart-table"])

  .config(function ($routeProvider) {
    $routeProvider.when("/examenkandidaten/", {
      templateUrl: "app/modules/examenkandidaten/examenkandidaten.view.html",
      controller: "ExamenKandidatenController",
      title: "Examenkandidaten",
      navigation_id: "examenkandidaten",
    });
  })

  .controller("ExamenKandidatenController", [
    "$scope",
    "$resource",
    "backendBaseUrl",
    "$window",
    "$location",
    "notificationservice",
    "$log",
    function (
      $scope,
      $resource,
      backendBaseUrl,
      $window,
      $location,
      notificationService,
      $log
    ) {
      $scope.kandidaatDetails = function (kandidaatId) {
        $location.path("/examenkandidaat/" + kandidaatId);
      };

      $scope.search = function () {
        console.log($scope.query);
        if ($scope.query != "") {
          $resource(backendBaseUrl + "/deelnemer/search/", {
            searchQuery: $scope.query,
          })
            .query()
            .$promise.then(
              function success(result) {
                console.log(result);
                $scope.deelnemers = result;
              },
              function failure(result) {
                $log.error(result);
              }
            );
        } else {
          $scope.deelnemers = [];
        }
      };

      // // config
      // $scope.itemsPerPage = 10;

      // $scope.bestellingenresult = [];

      // $scope.displayedbestellingenresult = [];
      // $scope.totalItems = 0;

      // //calling this with 0 means we want all the results
      // getBestellingen(0);

      // $translate("bestellingen.status.alles").then(function () {
      //     $scope.status = [{
      //             id: "ALLES",
      //             text: $translate.instant("bestellingen.status.alles"),
      //         },
      //         {
      //             id: "Open",
      //             text: $translate.instant("bestellingen.status.open"),
      //         },
      //         {
      //             id: "Verwerkt",
      //             text: $translate.instant("bestellingen.status.verwerkt"),
      //         },
      //     ];
      // });

      // function getBestellingen(pageNumber) {
      //     $resource(backendBaseUrl + "/bestellingen")
      //         .get({
      //             pageNumber: pageNumber,
      //             pageSize: $scope.itemsPerPage,
      //         })
      //         .$promise.then(function success(result) {
      //             $scope.bestellingenresult = result.bestellingen;
      //             $scope.totalItems = result.totalItems;
      //         });
      // }

      // $scope.afronden = function (id) {
      //     $resource(backendBaseUrl + "/openbetaling/")
      //         .save({
      //             id: id,
      //         })
      //         .$promise.then(
      //             function succes(result) {
      //                 $window.location.href = result.returnUrl;
      //             },
      //             function failure(result) {
      //                 if (result.status === 403) {
      //                     notificationService.info(
      //                         $translate.instant("bestellingen.status_gewijzigd")
      //                     );
      //                     $location.path("/bestellingen");
      //                     $scope.$apply();
      //                 }
      //             }
      //         );
      // };

      // $scope.downloadFactuur = function (bestelling) {
      //     downloadfileService.downloadFactuur(bestelling.factuurId);
      // };

      // $scope.getBestellingStatusTranslation = function (status) {
      //     return $translate.instant(
      //         "bestellingen.status." + status.toLowerCase()
      //     );
      // };

      // Do some formatting on select2 options
      // $scope.s2_status_options = {
      //     language: "nl",
      //     formatSelection: function (status) {
      //         return (
      //             "Filter op status:" +
      //             " " +
      //             "<strong>" +
      //             status.text.toLowerCase() +
      //             "</strong>"
      //         );
      //     },
      //     allowClear: false,
      //     //don't display the searchbar
      //     minimumResultsForSearch: -1,
      // };
    },
  ]);
