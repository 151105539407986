"use strict";

var module = angular.module("aanstellingspas.account", [
	"aanstellingspas.service.bedrijfsservice",
	"rt.select2",
	"ngMessages",
]);

module.config(function ($routeProvider) {
	// bind a route to a view
	$routeProvider.when("/account/:id/:tab?", {
		templateUrl: "app/modules/account/account.view.html",
		controller: "AccountController",
		title: "Account",
		reloadOnSearch: false,
		navigation_id: "accountbeheer",
	});
});
module.config(function ($routeProvider) {
	// bind a route to a view
	$routeProvider.when("/organisatie/:organisatieid/:tab?", {
		templateUrl: "app/modules/account/account.view.html",
		controller: "AccountController",
		title: "Account",
		reloadOnSearch: false,
		navigation_id: "accountbeheer",
	});
});

module.controller("AccountController", [
	"$scope",
	"authService",
	"$http",
	"$location",
	"backendBaseUrl",
	"$log",
	"$resource",
	"$localStorage",
	"notificationservice",
	"$location",
	"$filter",
	"Upload",
	"$routeParams",
	function (
		$scope,
		authService,
		$http,
		$location,
		backendBaseUrl,
		$log,
		$resource,
		$localStorage,
		notificationservice,
		$route,
		$filter,
		Upload,
		$routeParams
	) {
		if ($routeParams.tab) {
			$scope.activetab = $routeParams.tab;
		} else {
			$scope.activetab = "gegevens";
		}

		$scope.bestellingen = [];
		$scope.unfilteredBestellingen = [];
		$scope.aanvragen = [];
		$scope.examens = [];
		$scope.vrijstellingen = [];
		$scope.organisatie = {};
		$scope.accountId = $routeParams.id;
		$scope.showNoBetalingBestellingen = false;

		// /account/:id/ or organisatie/:id/
		$scope.baseroute = $location.path().replace($scope.activetab, "");
		//this can be null
		$scope.organisatieid = $routeParams.organisatieid;

		$scope.backendBaseUrl = backendBaseUrl;
		$scope.account = {};

		if ($scope.organisatieid) {
			$resource(backendBaseUrl + "/organisatie/:organisatieid/account/", {
				organisatieid: $scope.organisatieid,
			})
				.get()
				.$promise.then(
					function success(result) {
						$scope.account = result;
						$scope.getOrganisatieDetails();
					},
					function failure(result) {
						if (result.status === 404) {
							notificationservice.info("Account niet gevonden");
							//$location.path('/accountbeheer');
						} else {
							$log.error(result);
						}
					}
				);
		} else {
			$resource(backendBaseUrl + "/account/" + $scope.accountId)
				.get()
				.$promise.then(
					function success(result) {
						$scope.account = result;
						$scope.getOrganisatieDetails();
					},
					function failure(result) {
						if (result.status === 404) {
							notificationservice.info("Account niet gevonden");
							$location.path("/accountbeheer");
						} else {
							$log.error(result);
						}
					}
				);
		}
		$scope.getOrganisatieDetails = function () {
			$resource(backendBaseUrl + "/organisatie/:organisatieid", {
				organisatieid: $scope.account.organisatieId,
			})
				.get()
				.$promise.then(
					function success(result) {
						$scope.organisatie = result;
						$scope.navigateToTab($scope.activetab);
					},
					function failure(result) {
						$log.error(result);
					}
				);
		};
		$scope.clickBestellingen = function () {
			if ($scope.bestellingen.length <= 1) {
				$scope.refreshBestellingen();
			}
		};

		$scope.toggleFilter = function () {
			//apply the filter or set bestellingen to the unfiltered collection
			if (!$scope.showNoBetalingBestellingen) {
				$scope.bestellingen = $scope.filterNoBetaling($scope.unfilteredBestellingen);
			} else {
				$scope.bestellingen = $scope.unfilteredBestellingen;
			}
		};

		$scope.filterNoBetaling = function (bestellingen) {
			return _.filter($scope.unfilteredBestellingen, function (bestelling) {
				return bestelling.status !== "Bestelling has no betaling";
			});
		};

		$scope.refreshBestellingen = function () {
			$resource(backendBaseUrl + "/organisatie/:organisatieid/bestellingen", {
				organisatieid: $scope.account.organisatieId,
			})
				.query()
				.$promise.then(
					function success(result) {
						//always set this collection to the result from the backend
						$scope.unfilteredBestellingen = result;
						//on first load filter is always false
						$scope.bestellingen = $scope.filterNoBetaling($scope.unfilteredBestellingen);
					},
					function failure(result) {
						$log.error(result);
					}
				);
		};

		$scope.clickGegevens = function () {};

		$scope.clickAanvragen = function () {
			if ($scope.aanvragen.length <= 1) {
				$resource(backendBaseUrl + "/organisatie/:organisatieid/pasaanvragen", {
					organisatieid: $scope.account.organisatieId,
				})
					.query()
					.$promise.then(
						function success(result) {
							$scope.aanvragen = result;
						},
						function failure(result) {
							$log.error(result);
						}
					);
			}
		};

		$scope.navigateToTab = function (tabName) {
			switch (tabName) {
				case "gegevens":
					$scope.clickGegevens();
					break;
				case "bestellingen":
					$scope.clickBestellingen();
					break;
				case "aanvragen":
					$scope.clickAanvragen();
					break;
				case "examens":
					$scope.clickExamens();
					break;
				case "vrijstellingen":
					$scope.clickVrijstellingen();
					break;
				default:
					break;
			}
		};

		$scope.clickExamens = function () {
			if ($scope.aanvragen.length <= 1) {
				$resource(backendBaseUrl + "/organisatie/:organisatieid/examens", {
					organisatieid: $scope.account.organisatieId,
				})
					.query()
					.$promise.then(
						function success(result) {
							$scope.examens = result;
						},
						function failure(result) {
							$log.error(result);
						}
					);
			}
		};

		$scope.clickVrijstellingen = function () {
			$resource(backendBaseUrl + "/vrijstelling/:organisatieid", {
				organisatieid: $scope.account.organisatieId,
			})
				.query()
				.$promise.then(
					function success(result) {
						$scope.vrijstellingen = result;
					},
					function failure(result) {
						$log.error(result);
					}
				);
		};

		$scope.toggleGeenBeperking = function () {
			$resource(backendBaseUrl + "/provincie/:organisatieid/beperking", null, {
				update: { method: "PUT" },
			})
				.update(
					{
						organisatieid: $scope.account.organisatieId,
					},
					{
						canUseGeen: $scope.organisatie.beperkingSwitch,
					}
				)
				.$promise.then(function success() {
					notificationservice.success("Recht op geen beperking succesvol ingesteld");
				}),
				function failure() {
					notificationservice.failure("Recht op geen beperking niet succesvol ingesteld");
				};
		};

		$scope.deleteAccount = function () {
			var confirmation = confirm("Are you sure you want to delete your account?");
			if (confirmation) {
				$http({
					method: "DELETE",
					url: backendBaseUrl + "/account/" + $scope.accountId + "/deleteaccount",
				}).then(
					function success(response) {
						notificationservice.success("Account is succesvol verwijderd");
						$location.path("/accountbeheer/");
					},
					function failure(response) {
						notificationservice.failure(
							"Er is een probleem bij het verwijderen van het account:",
							response
						);
					}
				);
			}
		};

		$scope.goToGemeenteKeuring = function (id) {
			$location.path("/gemeentegoedkeuring/" + id);
		};

		$scope.goToRijkswaterstaatKeuring = function (id) {
			$location.path("/rijkswaterstaatgebruikergoedkeuring/" + id);
		};

		$scope.goToProvincieKeuring = function (id) {
			$location.path("/provinciegoedkeuring/" + id);
		};

		$scope.goToBvoKeuring = function (id) {
			$location.path("/keurbvo/" + id);
		};

		$scope.setHiddenForFrontOffice = function () {
			$resource(backendBaseUrl + "/organisatie/:organisatieid/hideforfrontoffice", null, {
				update: { method: "PUT" },
			})
				.update(
					{
						organisatieid: $scope.account.organisatieId,
					},
					$scope.organisatie.hideForFrontoffice
				)
				.$promise.then(function success() {
					notificationservice.success("Wijziging is succesvol opgeslagen");
				}),
				function failure() {
					notificationservice.failure("Wijziging is niet succesvol opgeslagen");
				};
		};

		$scope.setHiddenForExamenplanner = function () {
			$resource(backendBaseUrl + "/organisatie/:organisatieid/hideforexamenplanner", null, {
				update: { method: "PUT" },
			})
				.update(
					{
						organisatieid: $scope.account.organisatieId,
					},
					$scope.organisatie.hideForExamenplanner
				)
				.$promise.then(function success() {
					notificationservice.success("Wijziging is succesvol opgeslagen");
				}),
				function failure() {
					notificationservice.failure("Wijziging is niet succesvol opgeslagen");
				};
		};
	},
]);
