"use strict";

var module = angular.module("aanstellingspas.gedelegeerde", [
	"aanstellingspas.service.bedrijfsservice",
	"rt.select2",
	"ngMessages",
	"validexamenblokdate",
]);

module.config(function ($routeProvider) {
	// bind a route to a view
	$routeProvider.when("/gedelegeerde/:id?", {
		templateUrl: "app/modules/gedelegeerde/gedelegeerde.view.html",
		controller: "GedelegeerdeController",
		title: "Gedelegeerde",
		reloadOnSearch: false,
		navigation_id: "gedelegeerden",
	});
});

module.controller("GedelegeerdeController", [
	"$scope",
	"authService",
	"$http",
	"$location",
	"backendBaseUrl",
	"$log",
	"$resource",
	"$localStorage",
	"notificationservice",
	"$route",
	"$filter",
	"Upload",
	"$routeParams",
	"$rootScope",
	function (
		$scope,
		authService,
		$http,
		$location,
		backendBaseUrl,
		$log,
		$resource,
		$localStorage,
		notificationservice,
		$route,
		$filter,
		Upload,
		$routeParams,
		$rootScope
	) {
		$scope.gedelegeerdeId = $routeParams.id;
		$scope.updatingGedelegeerde = false;
		if ($scope.gedelegeerdeId) {
			$scope.updatingGedelegeerde = true;
		}

		$scope.backendBaseUrl = backendBaseUrl;
		$scope.gedelegeerde = {};
		$scope.regios = [];

		//if an id was found in the routeparams, we are editing an existing gedelegeerde. Otherwise its a new one and we use the empty model.
		if ($scope.gedelegeerdeId) {
			$resource(backendBaseUrl + "/gedelegeerde/" + $scope.gedelegeerdeId)
				.get()
				.$promise.then(
					function success(gedelegeerdeResult) {
						$resource(backendBaseUrl + "/politieregio/gemeente")
							.query()
							.$promise.then(
								function success(regioresult) {
									$scope.regios = regioresult;
									$scope.gedelegeerde = gedelegeerdeResult;
									_.each($scope.gedelegeerde.voorkeursGemeenten, function (gemeenteId) {
										_.each($scope.regios, function (regio) {
											var gemeente = _.find(regio.gemeentes, function (g) {
												return g.id === gemeenteId;
											});
											if (gemeente) {
												gemeente.selected = true;
											}
										});
									});
								},
								function failure(result) {
									$log.error(result);
								}
							);
					},
					function failure(result) {
						if (result.status === 404) {
							notificationservice.info("Gedelegeerde niet gevonden");
							$location.path("/gedelegeerden");
						} else {
							$log.error(result);
						}
					}
				);
		}
		/*
		 * Does the actual submitting of the data to the server,
		 * after checking if gedeleerde and date is available
		 */

		$scope.updateOrCreateGedelegeerde = function () {
			var gemeentes = [];
			//get alle gemeentes
			_.each($scope.regios, function (regio) {
				// Array.prototype.push()
				gemeentes = gemeentes.concat(regio.gemeentes);
			});
			//filter them to get the selected ones and get their id's
			$scope.gedelegeerde.voorkeursGemeenten = _.chain(gemeentes).filter("selected").map("id").value();

			var updateRequest = $resource(
				backendBaseUrl + "/gedelegeerde/" + $scope.gedelegeerdeId,
				{},
				{
					update: {
						method: "PUT",
						isArray: false,
					},
				}
			);

			var createRequest = $resource(
				backendBaseUrl + "/gedelegeerde",
				{},
				{
					create: {
						method: "POST",
					},
				}
			);

			//if we have an id, we are updating an existing gedelegeerde. if not, he be new
			if ($scope.gedelegeerdeId) {
				updateRequest.update({}, $scope.gedelegeerde).$promise.then(function success() {
					notificationservice.success("Gedelegeerde geupdate");
					$route.reload();
				});
			} else {
				createRequest.create({}, $scope.gedelegeerde).$promise.then(
					function success() {
						notificationservice.success("Gedelegeerde gemaakt");
						$route.reload();
					},
					function failure(result) {
						if (result.status === 409) {
							notificationservice.error("Gedelegeerde bestond al");
						}
					}
				);
			}
		};

		/*
		 * reloads the view
		 */
		$scope.reloadView = function () {
			$route.reload();
			$log.debug("reloading the view, you will never see this message");
		};

		$scope.selectRegio = function (regio) {
			if (_.every(regio.gemeentes, "selected")) {
				_.each(regio.gemeentes, function (gemeente) {
					gemeente.selected = false;
				});
			} else {
				_.each(regio.gemeentes, function (gemeente) {
					gemeente.selected = true;
				});
			}
		};

		$scope.checkemailaddressAvailable = function () {
			var emailaddress = $scope.gedelegeerde.emailadres;
			if (!_.isEmpty(emailaddress)) {
				authService.emailaddressAvailable(emailaddress).$promise.then(
					function (result) {
						$scope.gedelegeerdeForm.emailadres.$setValidity("unique", result.available);
					},
					function failure() {}
				);
			}
		};
		$scope.invite = function () {
			$resource(backendBaseUrl + "/gedelegeerde/" + $scope.gedelegeerdeId + "/invite")
				.get()
				.$promise.then(
					function success() {
						notificationservice.success("Gedelegeerde uitgenodigd");
						$scope.reloadView();
					},
					function failure(error) {
						console.log(error);
					}
				);
		};
	},
]);
