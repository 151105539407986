"use strict";

var module = angular.module("aanstellingspas.tegoedboncodes", ["smart-table"]);

module.config(function ($routeProvider) {
	$routeProvider.when("/tegoedboncodes", {
		templateUrl: "app/modules/tegoedboncodes/tegoedboncodes.view.html",
		controller: "TegoedbonCodesController",
		title: "Tegoedbon codes",
		navigation_id: "tegoedboncodes",
	});
});

module.controller("TegoedbonCodesController", [
	"$scope",
	"$log",
	"$resource",
	"backendBaseUrl",
	"$location",
	"notificationservice",
	"$route",
	function ($scope, $log, $resource, backendBaseUrl, $location, notificationservice, $route) {
		console.log("Tegoedboncodes");
		$scope.backendBaseUrl = backendBaseUrl;

		$scope.tegoedboncodes = [];
		$scope.bedrijven = [];
		$scope.tegoedboncode = {};

		$resource(backendBaseUrl + "/organisatie")
			.query()
			.$promise.then(
				function success(result) {
					$scope.bedrijven = result;
				},
				function failure(result) {
					if (result.status === 404) {
						notificationservice.info("Er zijn geen bedrijven gevonden.");
						$location.path("/dashboard");
					} else {
						$log.error(result);
					}
				}
			);
		$scope.getEmailForOrganisatieId = function (organisatieId) {
			var organisatie = _.find($scope.bedrijven, function (b) {
				return b.id === organisatieId;
			});
			if (organisatie) {
				return organisatie.emailadresennaam;
			} else {
				return organisatieId;
			}
		};
		$resource(backendBaseUrl + "/tegoedcode")
			.query()
			.$promise.then(
				function success(result) {
					$scope.tegoedcodes = result;

					_.forEach($scope.tegoedcodes, function (tegoedcode) {
						const datum = new Date(tegoedcode.createdAt);
						tegoedcode.codeEmailDatum = [
							datum.getDate() + "-" + (datum.getMonth() + 1) + "-" + datum.getFullYear(),
							tegoedcode.code,
							tegoedcode.verstuurdNaarOrganisatieId,
						];
					});
				},
				function failure(result) {
					$log.error(result);
				}
			);

		$scope.$watch("model.verstuurdNaarOrganisatieId", function (newValue) {
			var bedrijf = _.find($scope.bedrijven, function (o) {
				return o.id === newValue;
			});
			if (bedrijf !== null && bedrijf !== undefined) {
				$scope.bedrijfsnaam = bedrijf.naam;
			}
		});

		$scope.generateAndSendCode = function () {
			var createRequest = $resource(
				backendBaseUrl + "/tegoedcode",
				{},
				{
					create: {
						method: "POST",
					},
				}
			);

			createRequest.create({}, $scope.tegoedboncode).$promise.then(
				function success() {
					notificationservice.success("Tegoedboncode gegenereerd");
					$route.reload();
				},
				function failure(result) {
					$log.error(result);
					notificationservice.error("Tegoedboncode niet gegenereerd");
				}
			);
		};
	},
]);
