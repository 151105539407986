"use strict";

var module = angular.module("aanstellingspas.dashboard", ["smart-table"]);

module.config(function ($routeProvider) {
	$routeProvider.when("/dashboard", {
		templateUrl: "app/modules/dashboard/dashboard.view.html",
		controller: "DashboardController",
		title: "Dashboard",
		navigation_id: "dashboard",
	});
});

module.controller("DashboardController", [
	"$scope",
	"$http",
	"$log",
	"$localStorage",
	"notificationservice",
	"$resource",
	"backendBaseUrl",
	"$location",
	"$rootScope",
	function (
		$scope,
		$http,
		$log,
		$localStorage,
		notificationService,
		$resource,
		backendBaseUrl,
		$location,
		$rootScope
	) {
		if ($rootScope.isInRole("secretariaatexamen")) {
			$location.path("/examens/");
		}
		$scope.aantalpasaanvragen = 0;
		$scope.shownPassen = [];
		/*
		 *
		 */
		function getAmountRemainingBvos() {
			$resource(backendBaseUrl + "/getamountremainingbvos")
				.get()
				.$promise.then(
					function (result) {
						$scope.amountBvosRemaining = result.amountRemainingBvos;
					},
					function (error) {
						$log.error("Kon aantal resterende bvos niet ophalen");
						$scope.amountBvosRemaining = false;
					}
				);
		}
		/*
		 * Gets the amount of printable beroepsverkeersregelaarspassen
		 */
		function getAmountPrintablePassen() {
			$resource(backendBaseUrl + "/pasexport/amountremainingberoepsverkeersregelaarspassen")
				.get()
				.$promise.then(
					function (result) {
						$scope.amountPassenRemaining = result.amountRemainingPassen;
					},
					function (error) {
						$log.error("Kon aantal resterende passen niet ophalen");
						$scope.amountPassenRemaining = false;
					}
				);
		}
		/*
		 * Gets a list of the latest saved blobs (passenpakket)
		 */
		function getLatestUploads() {
			$resource(backendBaseUrl + "/pasexport/pasexportblobs")
				.query()
				.$promise.then(
					function (result) {
						$scope.latestUploads = result;
						$scope.shownPassen = result;
					},
					function (error) {
						$log.error("Kon laatste uploads niet ophalen");
						$scope.latestUploads = false;
						$scope.shownPassen = [];
					}
				);
		}

		function getBeoordelingen() {
			$resource(backendBaseUrl + "/beoordelingen")
				.query()
				.$promise.then(
					function (result) {
						$scope.beoordelingen = result;
					},
					function (error) {
						$log.error("Kon beoordelingen niet ophalen");
					}
				);
		}

		/*
		 * COmmands the backend to start processing beroepsverkeersregelaarspassen queue
		 */
		$scope.packQueue = function () {
			$resource(backendBaseUrl + "/pasexport/beroepsverkeersregelaarspasexport")
				.get()
				.$promise.then(
					function (result) {
						getAmountPrintablePassen();
						getLatestUploads();
						$scope.$digest();
					},
					function (error) {
						$log.error("Fout bij inpakken");
					}
				);
		};
		function getAantalKeuringen() {
			$resource(backendBaseUrl + "/approvable-pasaanvraag")
				.query()
				.$promise.then(
					function success(result) {
						$scope.aantalpasaanvragen = result.length;
					},
					function failure(result) {
						$log.error(result);
					}
				);
		}

		function getAantalGemeenteKeuringen() {
			$resource(backendBaseUrl + "/count-approvable-gemeentegoedkeuring/")
				.get()
				.$promise.then(
					function success(result) {
						$scope.aantalgemeentekeuringen = result.amount;
					},
					function failure(result) {
						$log.error(result);
					}
				);
		}

		var roles = ["secretariaatpas", "admin"];

		if ($rootScope.isInRoles(roles)) {
			getAmountRemainingBvos();
			getAmountPrintablePassen();
			getLatestUploads();
			getAantalKeuringen();
			getAantalGemeenteKeuringen();
			getBeoordelingen();
		}

		$scope.goToGemeenteKeuring = function (id) {
			$location.path("/gemeentegoedkeuring/" + id);
		};

		$scope.goToRijkswaterstaatKeuring = function (id) {
			$location.path("/rijkswaterstaatgebruikergoedkeuring/" + id);
		};

		$scope.goToProvincieKeuring = function (id) {
			$location.path("/provinciegoedkeuring/" + id);
		};

		$scope.goToBvoKeuring = function (id) {
			$location.path("/keurbvo/" + id);
		};

		$scope.goToPasaanvraagKeuren = function (id) {
			$location.path("/pasaanvraagkeuren/" + id);
		};
	},
]);
