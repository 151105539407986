"use strict";

var module = angular.module("aanstellingspas.keurbvo", [
  "aanstellingspas.service.bedrijfsservice",
]);

module.config(function ($routeProvider) {
  // bind a route to a view
  $routeProvider.when("/keurbvo", {
    templateUrl: "app/modules/keurbvo/keurbvo.view.html",
    controller: "KeurBvoController",
    title: "Bewijs van Opdrachten keuren",
    reloadOnSearch: false,
  });

  $routeProvider.when("/keurbvo/:id", {
    templateUrl: "app/modules/keurbvo/keurbvo.view.html",
    controller: "KeurBvoController",
    title: "Bewijs van Opdrachten keuren",
    reloadOnSearch: false,
  });
});

module.filter("splitByPipes", function () {
  // splits a string with double pipe delimiters to array for iteration in view
  return function (value) {
    if (!value.length) return;
    return value.split("||");
  };
});

module.controller("KeurBvoController", [
  "$scope",
  "authService",
  "$http",
  "$location",
  "backendBaseUrl",
  "$log",
  "$resource",
  "$localStorage",
  "$filter",
  "notificationservice",
  "$route",
  "$anchorScroll",
  "$timeout",
  "$routeParams",
  "$sce",
  function (
    $scope,
    authService,
    $http,
    $location,
    backendBaseUrl,
    $log,
    $resource,
    $localStorage,
    $filter,
    notificationservice,
    $route,
    $anchorScroll,
    $timeout,
    $routeParams,
    $sce
  ) {
    $scope.backendBaseUrl = backendBaseUrl;

    // get standaard redenen from database
    getRedenen();

    getAmountRemainingBvos();

    $scope.anyNotYetApprovedDocuments = function () {
      var anyNotYetApprovedDocuments = _.every(
        $scope.bvo.indienPogingen[0].documents,
        function (document) {
          return document.hasOwnProperty("goedgekeurd");
        }
      );

      return anyNotYetApprovedDocuments;
    };

    $scope.afkeurButtonDisabled = function () {
      if ($scope.bvo != undefined) {
        if ($scope.anyNotYetApprovedDocuments() == false) {
          return true;
        }

        var disapprovedDocuments = _.some(
          $scope.bvo.indienPogingen[0].documents,
          ["goedgekeurd", "false"]
        );

        // if (disapprovedDocuments === true) {
        //     return false;
        // }

        // else {
        //     return true;
        // }
        return false;
      }
    };

    $scope.goedkeurButtonDisabled = function () {
      if ($scope.bvo != undefined) {
        var allDocumentsApproved = _.every(
          $scope.bvo.indienPogingen[0].documents,
          ["goedgekeurd", "true"]
        );

        if (allDocumentsApproved === true) {
          return false;
        } else {
          return true;
        }
      }
    };

    // for scrolling to certain div ID's
    $scope.startAfkeuring = function () {
      $scope.naarAfkeuren = true;
      $timeout(
        function () {
          $location.hash("afkeuren");
          $anchorScroll.yOffset = 60;
          $anchorScroll();
        },
        0,
        false
      );
    };

    $scope.getById = function () {
      $resource(
        backendBaseUrl + "/approvablebewijsvanopdracht/" + $routeParams.id
      )
        .get()
        .$promise.then(
          function success(result) {
            $scope.bvo = result;
            $scope.selectedIndienpoging = $scope.bvo.indienPogingen[0].id;
          },
          function failure(result) {
            if (result.status === 404) {
              notificationservice.info("Bvo niet gevonden");
              $location.path("/dashboard");
            } else {
              $log.error(result);
            }
          }
        );
    };

    // Bvo id given, getting that one from backend
    if ($routeParams.id) {
      $scope.getById();
    }

    // No Bvo id given, getting next one from backend
    else {
      // getting next approvable bvo from queue in database (first in first out)
      $resource(backendBaseUrl + "/nextapprovablebewijsvanopdracht")
        .get()
        .$promise.then(
          function success(result) {
            $scope.bvo = result;
            $scope.selectedIndienpoging = $scope.bvo.indienPogingen[0].id;
          },
          function failure(result) {
            if (result.status === 404) {
              notificationservice.info(
                "Er zijn op dit moment geen Bewijzen van Opdrachten te keuren."
              );
              $location.path("/dashboard");
            } else {
              $log.error(result);
            }
          }
        );
    }

    $scope.$on("commented", function () {
      $scope.getById();
    });
    $scope.$on("snoozed", function () {
      $scope.getById();
    });

    /*
     * Function checks if afkeuren button should be disabled
     */
    $scope.checkIfDisabled = function () {
      if ($scope.customAfkeurReden) {
        return false;
      }
      if (_.find($scope.redenen, { gekozen: true })) {
        return false;
      }
      return true;
    };

    $scope.getFileType = function (document) {
      var lastThreeCharacters = document.originalFileName
        .substr(document.originalFileName.length - 3)
        .toLowerCase();

      if (lastThreeCharacters == "pdf") {
        return "document";
      } else {
        return "picture";
      }
    };

    /*
     * This function gets called when the user presses Goedkeuren
     * It then asks if he is sure. If yes, a request is passed to the backend
     * to approve this bewijs van opdrachten
     * Also, a klant is created in Digitale Factuur.
     */
    $scope.approve = function () {
      swal({
        title: "Goedkeuren",
        text: "Wilt u dit bewijs van opdrachten goedkeuren?",
        icon: "info",

        buttons: {
          confirm: {
            text: "Ja",
            value: true,
            visible: true,
            className: "",
            closeModal: true,
          },
          cancel: {
            text: "Nee, terug",
            value: false,
            visible: true,
            className: "",
            closeModal: true,
          },
        },
        closeOnClickOutside: false,
        closeOnEsc: false,
      }).then(
        function confirmAction(confirmed) {
          if (confirmed == true) {
            $resource(
              backendBaseUrl + "/bewijsvanopdrachtgoedkeuring/" + $scope.bvo.id,
              null,
              {
                update: { method: "PUT" },
              }
            )
              .update()
              .$promise.then(
                function success() {
                  notificationservice.success(
                    "Bewijs van Opdrachten van " +
                      $scope.bvo.bedrijf.bedrijfsnaam +
                      " gekeurd."
                  );
                  $location.path("/beoordelingen");
                },
                function failure(result) {
                  $log.debug(result);
                }
              );
          }
        },
        function cancelAction() {}
      );
    };
    $scope.getSafeHTML = function (string) {
      return $sce.trustAsHtml(string);
    };
    $scope.saveComment = function (comment) {
      $resource(
        backendBaseUrl + "/bewijsvanopdrachten/:id/comment",
        {
          id: $routeParams.id,
        },
        null
      )
        .save({
          content: comment,
        })
        .$promise.then(function success() {
          notificationservice.success("Reactie toegevoegd");
          $scope.$emit("ReloadData");
        }),
        function failure(result) {
          notificationservice.failure("Reactie toevoegen mislukt");
          $log.error(result);
        };
    };
    /*
     * This function gets called when the user presses Afkeuren
     * It then asks if he is sure. If yes, a request is passed to the backend
     * to disapprove this bewijs van opdrachten
     */
    $scope.disapprove = function () {
      var selectedRedenen = $filter("filter")($scope.redenen, {
        gekozen: true,
      });
      if ($scope.customAfkeurReden) {
        selectedRedenen.push({
          reden: $scope.customAfkeurReden,
          gekozen: true,
        });
      }

      // creating empty string to fill with selected disapprovereasons
      var afkeurRedenen = "";
      _.each(selectedRedenen, function (element) {
        if (afkeurRedenen.length === 0) {
          afkeurRedenen = element.reden;
        } else {
          afkeurRedenen = afkeurRedenen + element.reden;
        }
      });

      swal({
        title: "Afkeuren",
        text: "Wilt u dit bewijs van opdrachten afkeuren?",
        icon: "info",
        buttons: {
          confirm: {
            text: "Ja",
            value: true,
            visible: true,
            className: "",
            closeModal: true,
          },
          cancel: {
            text: "Nee, terug",
            value: false,
            visible: true,
            className: "",
            closeModal: true,
          },
        },
        closeOnClickOutside: false,
        closeOnEsc: false,
      }).then(
        function confirmAction(confirmed) {
          if (confirmed == true) {
            $resource(
              backendBaseUrl + "/bewijsvanopdrachtafkeuring/" + $scope.bvo.id,
              null,
              {
                update: { method: "PUT" },
              }
            )
              .update({
                afkeurReden: afkeurRedenen,
                bvobestanden: $scope.bvo.indienPogingen[0].documents,
              })
              .$promise.then(
                function success() {
                  notificationservice.success(
                    "Bewijs van Opdrachten van " +
                      $scope.bvo.bedrijf.bedrijfsnaam +
                      " afgekeurd."
                  );
                  $location.path("/beoordelingen");
                },
                function failure(result) {
                  $log.debug(result);
                }
              );
          }
        },
        function cancelAction() {}
      );
    };

    function getRedenen() {
      $resource(backendBaseUrl + "/disapprovereason")
        .query()
        .$promise.then(
          function (result) {
            $scope.redenen = result;
          },
          function (error) {
            $scope.redenen = false;
          }
        );
    }

    function getAmountRemainingBvos() {
      $resource(backendBaseUrl + "/getamountremainingbvos")
        .get()
        .$promise.then(
          function (result) {
            $scope.amountBvosRemaining = result.amountRemainingBvos;
          },
          function (error) {
            $log.error("Kon aantal resterende bvos niet ophalen");
            $scope.amountBvosRemaining = false;
          }
        );
    }
  },
]);
