"use strict";

var module = angular.module("aanstellingspas.provinciegoedkeuring", [
  "aanstellingspas.service.bedrijfsservice",
  "smart-table",
]);

module.config(function ($routeProvider) {
  $routeProvider.when("/provinciegoedkeuring/:id", {
    templateUrl:
      "app/modules/provinciegoedkeuring/provinciegoedkeuring.view.html",
    controller: "ProvincieGoedkeuringController",
    title: "Provincie account goedkeuren",
    navigation_id: "specialeaccounts",
    reloadOnSearch: false,
  });
});

module.controller("ProvincieGoedkeuringController", [
  "$scope",
  "authService",
  "$http",
  "$location",
  "backendBaseUrl",
  "$log",
  "$resource",
  "$localStorage",
  "notificationservice",
  "$route",
  "$filter",
  "Upload",
  "$routeParams",
  "$rootScope",
  function (
    $scope,
    authService,
    $http,
    $location,
    backendBaseUrl,
    $log,
    $resource,
    $localStorage,
    notificationservice,
    $route,
    $filter,
    Upload,
    $routeParams,
    $rootScope
  ) {
    $scope.backendBaseUrl = backendBaseUrl;

    $resource(backendBaseUrl + "/provinciegoedkeuring/" + $routeParams.id)
      .get()
      .$promise.then(
        function success(result) {
          $scope.goedkeuring = result;
          $scope.reedsGoedgekeurd = $scope.goedkeuring.goedgekeurdDoorSVNL;

          var lastPartOfEmail = result.emailadres.split("@").pop();

          if (lastPartOfEmail === "rws.nl") {
            $scope.isRwsEmail = true;
          } else {
            $scope.isRwsEmail = false;
          }
        },
        function failure(result) {
          if (result.status === 404) {
            $location.path("/dashboard");
            notificationservice.error("Account niet gevonden");
          } else {
            $location.path("/dashboard");
            notificationservice.error("Er ging iets mis");
          }

          $log.error(result);
        }
      );

    /*
     * Aanvraag goedkeuren
     *
     */
    $scope.keurAanvraagGoed = function () {
      putGoedkeuring();
    };

    /*
     * Aanvraag afkeuren
     *
     */
    $scope.keurAanvraagAf = function () {
      putAfkeuring();
    };

    /*
     * Aanvraag verwijderen
     *
     */
    $scope.verwijderAanvraag = function () {
      deleteAanvraag();
    };
    $scope.contactgegevensGoedgekeurd = function () {
      $scope.contactgegevensapproved = true;
    };
    $scope.contactgegevensAfgekeurd = function () {
      $scope.contactgegevensapproved = false;
    };

    $scope.goedkeurButtonDisabled = function () {
      if ($scope.contactgegevensapproved != undefined) {
        if ($scope.contactgegevensapproved == true) {
          //only return false (thus not disabling the button) if both checks are approved
          return false;
        }
      }
      // disable the button
      return true;
    };

    $scope.afkeurButtonDisabled = function () {
      if ($scope.contactgegevensapproved != undefined) {
        if ($scope.contactgegevensapproved == false) {
          return false;
        }
      }
      // disable the button
      return true;
    };

    var putGoedkeuring = function () {
      // do the actual keuring
      $resource(
        backendBaseUrl + "/provinciegoedkeuring/:id/approve",
        {
          id: $routeParams.id,
        },
        {
          put: { method: "PUT" },
        }
      )
        .put()
        .$promise.then(function success() {
          notificationservice.success("Provincie gebruiker goedgekeurd");
          $location.path("/dashboard");
        });
    };

    var putAfkeuring = function () {
      // do the actual keuring
      $resource(
        backendBaseUrl + "/provinciegoedkeuring/:id/disapprove",
        {
          id: $routeParams.id,
        },
        {
          put: { method: "PUT" },
        }
      )
        .put()
        .$promise.then(function success() {
          notificationservice.success("Provincie gebruiker afgekeurd");
          $location.path("/dashboard");
        });
    };

    var deleteAanvraag = function () {
      // do the actual keuring
      $resource(
        backendBaseUrl + "/provinciegoedkeuring/:id/",
        {
          id: $routeParams.id,
        },
        {
          put: { method: "DELETE" },
        }
      )
        .put()
        .$promise.then(function success() {
          notificationservice.success("Aanvraag verwijderd");
          $location.path("/dashboard");
        });
    };
  },
]);
