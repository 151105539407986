"use strict";

var module = angular
  .module("aanstellingspas.service.servererrorinterceptorservice", [])
  .factory("serverErrorInterceptorService", function ($q, $location, $log) {
    var serverErrorInterceptorService = {};

    var _responseError = function (rejection) {
      if (rejection.status === 500 || rejection.status === 400) {
        swal({
          title: "Er is iets fout gegaan",
          text: "Probeer de actie nogmaals. Blijft het probleem aanhouden neem dan contact op met de helpdesk",
          icon: "error",
        });
      }

      return $q.reject(rejection);
    };

    serverErrorInterceptorService.responseError = _responseError;

    return serverErrorInterceptorService;
  });
