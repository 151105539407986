"use strict";

var module = angular.module("aanstellingspas.facturen", ["smart-table"]);

module.config(function ($routeProvider) {
  // bind a route to a view
  $routeProvider.when("/facturen/", {
    templateUrl: "app/modules/facturen/facturen.view.html",
    controller: "FacturenController",
    title: "Facturen",
    reloadOnSearch: false,
    navigation_id: "facturen",
  });
});

module.controller("FacturenController", [
  "$scope",
  "backendBaseUrl",
  "notificationservice",
  "$log",
  "$http",
  "FileSaver",
  "Blob",
  function (
    $scope,
    backendBaseUrl,
    notificationService,
    $log,
    $http,
    FileSaver,
    Blob
  ) {
    $scope.findFactuur = function (bestelNummer) {
      $http({
        method: "GET",
        url: backendBaseUrl + "/bestelling/" + bestelNummer + "/factuur",
      }).then(
        function success(result) {
          if (result.status === 200) {
            $scope.factuur = result.data;
            $scope.totaalprijs =
              $scope.factuur.totaalprijs_exclusief_btw +
              $scope.factuur.totaal_btw;
          } else if (result.status === 204) {
            notificationService.error(
              "Geen factuur gevonden voor deze bestelling"
            );
          }
        },
        function failure(result) {
          if (result.status === 404) {
            notificationService.error("Bestelling niet gevonden");
          } else {
            $log.error(result);
          }
        }
      );
    };

    $scope.downloadFactuur = function (bestelNummer) {
      $http({
        method: "GET",
        url: backendBaseUrl + "/bestelling/" + bestelNummer + "/factuurpdf",
        responseType: "arraybuffer",
      }).then(
        function successCallback(response) {
          var file = new Blob([response.data], {
            type: "application/pdf",
          });

          FileSaver.saveAs(file, "factuur-bestelling-" + bestelNummer + ".pdf");
        },
        function errorCallback(response) {
          // called asynchronously if an error occurs
          // or server returns response with an error status.
          notificationService.error("Factuur downloaden mislukt");
          $log.error(response.data);
        }
      );
    };
  },
]);
