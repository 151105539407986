var module = angular
  .module("aanstellingspas.service.bedrijfsservice", [])
  .factory("bedrijfsService", [
    "$localStorage",
    "backendBaseUrl",
    "$http",
    "$log",
    "$rootScope",
    "$resource",
    "$location",
    function (
      $localStorage,
      backendBaseUrl,
      $http,
      $log,
      $rootScope,
      $resource,
      $location
    ) {
      var bedrijfsServiceInstance = {};

      /**
       * Create a new bedrijf
       * @param  {string} email               email
       * @param  {string} username            username
       * @param  {string} password            password
       * @param  {string} kvkNummer           kvkNummer
       * @param  {string} subdossierNummer    subdossierNummer
       */
      bedrijfsServiceInstance.createBedrijf = function (
        email,
        contactpersoonnaam,
        password,
        kvkNummer,
        subdossierNummer
      ) {
        return $resource(backendBaseUrl + "/bedrijf").save({
          email: email,
          contactpersoonnaam: contactpersoonnaam,
          password: password,
          kvkNummer: kvkNummer,
          subdossierNummer: subdossierNummer,
        });
      };

      bedrijfsServiceInstance.getBedrijfResourceForUser = function (userId) {
        return $resource(backendBaseUrl + "/user/" + userId + "/bedrijf").get()
          .$promise;
      };

      bedrijfsServiceInstance.getBedrijfResourceForThisUser = function () {
        return this.getBedrijfResourceForUser($localStorage.authticket.user_id);
      };

      return bedrijfsServiceInstance;
    },
  ]);
