"use strict";

var module = angular.module("aanstellingspas.bestellingen", [
  "smart-table",
  "daterangepicker",
]);

module.config(function ($routeProvider) {
  $routeProvider.when("/bestellingen", {
    templateUrl: "app/modules/bestellingen/bestellingen.view.html",
    controller: "BestellingenController",
    title: "Bestellingen",
    navigation_id: "bestellingen",
  });
});

module.controller("BestellingenController", [
  "$scope",
  "$http",
  "$log",
  "$localStorage",
  "notificationservice",
  "$resource",
  "backendBaseUrl",
  "$location",
  function (
    $scope,
    $http,
    $log,
    $localStorage,
    notificationService,
    $resource,
    backendBaseUrl,
    $location
  ) {
    $scope.datePicker = {
      date: { startDate: null, endDate: null },
      options: {
        buttonClasses: "button",
        applyButtonClasses: "primary",
        cancelButtonClasses: "secondary",
        locale: {
          separator: " - ",
          format: "DD-MM-YYYY",
          monthNames: [
            "januari",
            "februari",
            "maart",
            "april",
            "mei",
            "juni",
            "juli",
            "augustus",
            "september",
            "oktober",
            "november",
            "december",
          ],
        },
        autoApply: true,
        linkedCalendars: false,
      },
    };

    $scope.getBestellingenOverview = function () {
      $resource(backendBaseUrl + "/bestelling/bestellingoverzicht")
        .query({
          startDate: $scope.datePicker.date.startDate.toDate(),
          endDate: $scope.datePicker.date.endDate.toDate(),
        })
        .$promise.then(
          function success(result) {
            $scope.bestellingen = result;
          },
          function failure(result) {
            console.log(result);
          }
        );
    };

    $scope.findBestelling = function (factuurNummer) {
      $resource(backendBaseUrl + "/factuur/" + factuurNummer + "/bestelling")
        .get()
        .$promise.then(
          function success(result) {
            if (angular.isDefined(result.id)) {
              $location.path("/bestelling/" + result.id);
            } else {
              notificationService.error(
                "Geen bestelling gevonden voor deze factuur"
              );
            }
          },
          function failure(result) {
            if (result.status === 404) {
              notificationService.error("Factuur niet gevonden");
            } else {
              $log.error(result);
            }
          }
        );
    };
  },
]);
