"use strict";

var module = angular.module("aanstellingspas.kruispunteditor", ["smart-table"]);

module.config(function ($routeProvider) {
	$routeProvider.when("/kruispunten/", {
		templateUrl: "app/modules/kruispunteditor/list.view.html",
		controller: "KruispuntEditorListController",
		title: "Kruispunten",
		navigation_id: "kruispunten",
	});

	$routeProvider.when("/kruispunt/:id?", {
		templateUrl: "app/modules/kruispunteditor/edit.view.html",
		controller: "KruispuntEditorEditController",
		title: "Kruispunten",
		navigation_id: "kruispunten",
	});
});

module.controller("KruispuntEditorListController", [
	"$scope",
	"$http",
	"$log",
	"$localStorage",
	"notificationservice",
	"$resource",
	"backendBaseUrl",
	"$location",
	function ($scope, $http, $log, $localStorage, notificationService, $resource, backendBaseUrl, $location) {
		//collection of all kruispunten from backend
		$scope.collection = [];

		//collection of kruispunten displayed in view
		$scope.displayed = [];

		$resource(backendBaseUrl + "/kruispunt")
			.query()
			.$promise.then(
				function success(result) {
					$scope.collection = result;
				},
				function failure(result) {
					$log.error(result);
				}
			);

		$scope.viewOpmerking = function (opmerking) {
			swal({
				text: opmerking,
				icon: "info",

				buttons: {
					confirm: {
						text: "Ok",
						value: true,
						visible: true,
						className: "",
						closeModal: true,
					},
				},

				closeOnClickOutside: false,
				closeOnEsc: false,
			}).then(function () {
				return;
			});
		};
	},
]);

module.controller("KruispuntEditorEditController", [
	"$scope",
	"$http",
	"$log",
	"$localStorage",
	"notificationservice",
	"$resource",
	"backendBaseUrl",
	"$location",
	"$routeParams",
	"$route",
	function (
		$scope,
		$http,
		$log,
		$localStorage,
		notificationService,
		$resource,
		backendBaseUrl,
		$location,
		$routeParams,
		$route
	) {
		$scope.reloadView = function () {
			$route.reload();
		};

		$scope.updateOrCreateKruispunt = function () {
			if ($routeParams.id) {
				$resource(backendBaseUrl + "/kruispunt/" + $routeParams.id, null, {
					update: {
						method: "PUT",
					},
				})
					.update($scope.kruispunt)
					.$promise.then(
						function success(result) {
							notificationService.info("Wijzigingen aan kruispunt opgeslagen");
							$location.path("/kruispunten");
						},
						function failure(result) {
							$log.error(result);
						}
					);
			} else {
				$resource(backendBaseUrl + "/kruispunt")
					.save($scope.kruispunt)
					.$promise.then(
						function success(result) {
							notificationService.info("Nieuw kruispunt opgeslagen");
							$location.path("/kruispunten");
						},
						function failure(result) {
							$log.error(result);
						}
					);
			}
		};

		if ($routeParams.id) {
			$resource(backendBaseUrl + "/kruispunt/:id")
				.get({
					id: $routeParams.id,
				})
				.$promise.then(
					function success(result) {
						$scope.kruispunt = result;
					},
					function failure(result) {
						$log.error(result);
					}
				);
		}

		$resource(backendBaseUrl + "/secretariaatplaats")
			.query()
			.$promise.then(
				function success(result) {
					$scope.plaatsen = result;
				},
				function failure(result) {
					$log.error(result);
				}
			);
	},
]);
