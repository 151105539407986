"use strict";

var module = angular.module("aanstellingspas.waarmerken", ["smart-table"]);

module.config(function ($routeProvider) {
  // bind a route to a view
  $routeProvider.when("/waarmerken/", {
    templateUrl: "app/modules/waarmerken/waarmerken.view.html",
    controller: "WaarmerkenController",
    title: "Waarmerken",
    reloadOnSearch: false,
    navigation_id: "waarmerken",
  });
});

module.controller("WaarmerkenController", [
  "$scope",
  "backendBaseUrl",
  "notificationservice",
  "$log",
  "$http",
  function ($scope, backendBaseUrl, notificationService, $log, $http) {
    $scope.findWaarmerk = function (waarmerkNummer) {
      $http({
        method: "GET",
        url: backendBaseUrl + "/waarmerk/" + waarmerkNummer,
      }).then(
        function success(result) {
          if (result.status === 200) {
            $scope.waarmerk = result.data;
          } else if (result.status === 204) {
            notificationService.error("Geen waarmerk gevonden");
          }
        },
        function failure(result) {
          if (result.status === 404) {
            notificationService.error("Waarmerk niet gevonden");
          } else {
            $log.error(result);
          }
        }
      );
    };
  },
]);
