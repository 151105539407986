"use strict";

angular.module("aanstellingspas.navigation", []).controller("navController", [
  "$scope",
  "authService",
  "$rootScope",
  "$location",
  function ($scope, authService, $rootScope, $location) {
    // all pages for rendering navigation in main menu
    $scope.pages = [
      {
        id: "0",
        url: "#/login",
        pageName: "Inloggen",
      },
      {
        id: "1",
        url: "#/logout",
        pageName: "Uitloggen",
      },
      {
        id: "2",
        url: "#/dashboard",
        pageName: "Dashboard",
      },
      {
        id: "3",
        url: "#/keurbvo",
        pageName: "BVO's keuren",
      },
    ];

    // returns page name for use in template
    $scope.pageName = $location.path();

    // hide menu by default
    $scope.showMenu = false;

    // toggles menu visibility on click in template
    $scope.toggleMenu = function () {
      if ($scope.showMenu === false) {
        $scope.showMenu = true;
      } else {
        $scope.showMenu = false;
      }
    };
  },
]);
