"use strict";

var module = angular.module("aanstellingspas.statistieken", ["smart-table", "daterangepicker"]);

module.config(function ($routeProvider) {
	// bind a route to a view
	$routeProvider.when("/statistieken/", {
		templateUrl: "app/modules/statistieken/statistieken.view.html",
		controller: "StatistiekenController",
		title: "Statistieken",
		reloadOnSearch: false,
		navigation_id: "statistieken",
	});
});

module.controller("StatistiekenController", [
	"$scope",
	"backendBaseUrl",
	"$log",
	"$resource",
	function ($scope, backendBaseUrl, $log, $resource) {
		$scope.statistieken = [];
		$scope.betaaldeProducten = [];
		$scope.totaalBetaaldeProducten = [];

		$scope.datePicker = {
			date: { startDate: null, endDate: null },
			options: {
				buttonClasses: "button",
				applyButtonClasses: "primary",
				cancelButtonClasses: "secondary",
				locale: {
					separator: " - ",
					format: "DD-MM-YYYY",
					monthNames: [
						"januari",
						"februari",
						"maart",
						"april",
						"mei",
						"juni",
						"juli",
						"augustus",
						"september",
						"oktober",
						"november",
						"december",
					],
				},
				autoApply: true,
				linkedCalendars: false,
			},
		};

		$scope.datePickerBetaaldeProducten = {
			date: { startDate: null, endDate: null },
			options: {
				buttonClasses: "button",
				applyButtonClasses: "primary",
				cancelButtonClasses: "secondary",
				locale: {
					separator: " - ",
					format: "DD-MM-YYYY",
					monthNames: [
						"januari",
						"februari",
						"maart",
						"april",
						"mei",
						"juni",
						"juli",
						"augustus",
						"september",
						"oktober",
						"november",
						"december",
					],
				},
				autoApply: true,
				linkedCalendars: false,
			},
		};

		$scope.datePickerTotaalBetaaldeProducten = {
			date: { startDate: null, endDate: null },
			options: {
				buttonClasses: "button",
				applyButtonClasses: "primary",
				cancelButtonClasses: "secondary",
				locale: {
					separator: " - ",
					format: "DD-MM-YYYY",
					monthNames: [
						"januari",
						"februari",
						"maart",
						"april",
						"mei",
						"juni",
						"juli",
						"augustus",
						"september",
						"oktober",
						"november",
						"december",
					],
				},
				autoApply: true,
				linkedCalendars: false,
			},
		};

		$scope.getStatistieken = function () {
			$resource(backendBaseUrl + "/statistieken/organisatieproducten")
				.query({
					van: $scope.datePicker.date.startDate.toDate(),
					tot: $scope.datePicker.date.endDate.toDate(),
				})
				.$promise.then(
					function success(result) {
						$scope.statistieken = result;
					},
					function failure(result) {
						$log.error(result);
					}
				);
		};

		$scope.getBetaaldeProducten = function () {
			$resource(backendBaseUrl + "/statistieken/betaaldeproducten")
				.query({
					van: $scope.datePickerBetaaldeProducten.date.startDate.toDate(),
					tot: $scope.datePickerBetaaldeProducten.date.endDate.toDate(),
				})
				.$promise.then(
					function success(result) {
						$scope.betaaldeProducten = result;
					},
					function failure(result) {
						$log.error(result);
					}
				);
		};

		$scope.getTotaalBetaaldeProducten = function () {
			$resource(backendBaseUrl + "/statistieken/totaalbetaaldeproducten")
				.query({
					van: $scope.datePickerTotaalBetaaldeProducten.date.startDate.toDate(),
					tot: $scope.datePickerTotaalBetaaldeProducten.date.endDate.toDate(),
				})
				.$promise.then(
					function success(result) {
						$scope.totaalBetaaldeProducten = result;
					},
					function failure(result) {
						$log.error(result);
					}
				);
		};
	},
]);
