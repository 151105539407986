"use strict";

var module = angular.module("aanstellingspas.gedelegeerden", ["smart-table"]);

module.config(function ($routeProvider) {
  $routeProvider.when("/gedelegeerden", {
    templateUrl: "app/modules/gedelegeerden/gedelegeerden.view.html",
    controller: "GedelegeerdenController",
    title: "Gedelegeerden",
    navigation_id: "gedelegeerden",
  });
});

module.controller("GedelegeerdenController", [
  "$scope",
  "$http",
  "$log",
  "$localStorage",
  "notificationservice",
  "$resource",
  "backendBaseUrl",
  "$location",
  function (
    $scope,
    $http,
    $log,
    $localStorage,
    notificationService,
    $resource,
    backendBaseUrl,
    $location
  ) {
    //collection of all gedelegeerden from backend
    $scope.collection = [];

    //collection of gedelegeerden displayed in view
    $scope.displayed = [];

    //get alle gedelegeerden for overzicht
    $resource(backendBaseUrl + "/gedelegeerde")
      .query()
      .$promise.then(
        function success(result) {
          $scope.collection = result;
        },
        function failure(result) {
          $log.error(result);
        }
      );
    $scope.invite = function () {
      $resource(backendBaseUrl + "/gedelegeerde/invite")
        .get()
        .$promise.then(
          function success() {},
          function failure(error) {
            console.log(error);
          }
        );
    };
  },
]);
