"use strict";

var module = angular.module("aanstellingspas.feedback", [
  "rt.select2",
  "ngMessages",
]);

module.config(function ($routeProvider) {
  // bind a route to a view
  $routeProvider.when("/feedback/", {
    templateUrl: "app/modules/feedback/feedback.view.html",
    controller: "FeedbackController",
    title: "Feedback",
    reloadOnSearch: false,
    navigation_id: "feedback",
  });
});

module.controller("FeedbackController", [
  "$scope",
  "backendBaseUrl",
  "$log",
  "$resource",
  function ($scope, backendBaseUrl, $log, $resource) {
    $resource(backendBaseUrl + "/feedback/", null)
      .query()
      .$promise.then(
        function success(result) {
          $scope.collection = result;
        },
        function failure(result) {
          $log.error(result);
        }
      );
  },
]);
module.filter("range", function () {
  return function (input, total) {
    total = parseInt(total);

    for (var i = 0; i < total; i++) {
      input.push(i);
    }

    return input;
  };
});
