"use strict";
var initInjector = angular.injector(["ng"]);

// Declare app level module which depends on views, and components
var app = angular
	.module("aanstellingspas", [
		"ngRoute",
		"ngResource",
		"ngStorage",
		"angular-loading-bar",
		"ngFileUpload",
		"lrNotifier",
		"dndLists",
		"angularMoment",
		"angular-clipboard",
		"ngFileSaver",
		//'rt.select2',
		// services
		"aanstellingspas.service.authinterceptorservice",
		"aanstellingspas.service.servererrorinterceptorservice",
		"aanstellingspas.service.authservice",
		"aanstellingspas.service.bedrijfsservice",
		"aanstellingspas.service.notificationservice",
		// components
		"aanstellingspas.navigation",
		"aanstellingspas.aanvragen",
		"aanstellingspas.login",
		"aanstellingspas.logout",
		"aanstellingspas.backendbaseurl",
		"aanstellingspas.dashboard",
		"aanstellingspas.keurbvo",
		"aanstellingspas.pasaanvraagkeuren",
		"aanstellingspas.gemeentegoedkeuring",
		"aanstellingspas.htmltest",
		"aanstellingspas.faq",
		"aanstellingspas.examen",
		"aanstellingspas.examenbloktoevoegen",
		"aanstellingspas.examens",
		"aanstellingspas.gedelegeerden",
		"aanstellingspas.gedelegeerde",
		"aanstellingspas.accountbeheer",
		"aanstellingspas.account",
		"aanstellingspas.auditlog",
		"aanstellingspas.passen",
		"aanstellingspas.rijkswaterstaatgebruikergoedkeuring",
		"aanstellingspas.provinciegoedkeuring",
		"aanstellingspas.beoordelingen",
		"aanstellingspas.pascreator",
		"aanstellingspas.fileuploader",
		"aanstellingspas.bestelling",
		"aanstellingspas.feedback",
		"aanstellingspas.kruispunteditor",
		"aanstellingspas.bestellingen",
		"aanstellingspas.plaatsen",
		"aanstellingspas.plaats",
		"aanstellingspas.deelnemer-beoordeling",
		"aanstellingspas.examenkandidaten",
		"aanstellingspas.examenkandidaat",
		"aanstellingspas.messageModal",
		"aanstellingspas.snoozeModal",
		"aanstellingspas.snoozeInfo",
		"aanstellingspas.statistieken",
		"aanstellingspas.facturen",
		"aanstellingspas.waarmerken",
		"aanstellingspas.tegoedboncodes",
	])
	.config([
		"$routeProvider",
		"cfpLoadingBarProvider",
		"$localStorageProvider",
		"$httpProvider",
		"lrNotifierProvider",
		"stConfig",
		function (
			$routeProvider,
			cfpLoadingBarProvider,
			$localStorageProvider,
			$httpProvider,
			lrNotifierProvider,
			stConfig
		) {
			//// disabling cache (problems with IE)
			//$httpProvider.defaults.cache = false;
			//if (!$httpProvider.defaults.headers.get) {
			//    $httpProvider.defaults.headers.get = {};
			//}
			//// disable IE ajax request caching
			//$httpProvider.defaults.headers.get['If-Modified-Since'] = '0';
			////.....here proceed with your routes

			// global route configuration, see the modules for individual routes
			$routeProvider.otherwise({
				redirectTo: "/dashboard",
			});

			$httpProvider.interceptors.push("authInterceptorService");
			$httpProvider.interceptors.push("serverErrorInterceptorService");

			// cfpLoadingBarProvider.parentSelector = '#loading-bar-container';
			cfpLoadingBarProvider.includeSpinner = true;
			// only show loading bar after 500 msec XHR loading
			cfpLoadingBarProvider.latencyThreshold = 500;

			$localStorageProvider.setKeyPrefix("aanstellingspas-backofficefrontend-");

			// sets notification lifetime in ms
			lrNotifierProvider.setTimer(5000); //in ms

			//set the pagination for smart-table to a nicer template
			stConfig.pagination.template = "app/shared/smart-table-templates/pagination.custom.html";
		},
	])
	.run(function (
		$rootScope,
		authService,
		$route,
		$log,
		$localStorage,
		$location,
		$window,
		notificationservice,
		$timeout
	) {
		$rootScope.$on("$routeChangeSuccess", function (currentRoute, previousRoute) {
			//Change page title, based on Route information
			if (!$route.current.title || $route.current.title === null) {
				$rootScope.title = "SVNL Backoffice";
				$log.error("Page title not set, enter page title in module router");
			} else {
				$rootScope.title = $route.current.title;
			}

			// set the current navigation id so we can highlight a button in the lefthand navigation
			// dont forget to set this value in each module that you display in the lefthand navigation
			$rootScope.current_navigation_id = $route.current.navigation_id;

			notificationservice.getNotifications();
		});

		$rootScope.$on("$routeChangeStart", function (event, nextRoute, currentRoute) {
			if ($rootScope.loggedin == false) {
				if (nextRoute.allowUnauthorized == true) {
					return;
				} else {
					event.preventDefault();
					var redirectUrl = $location.path();
					// User isn�t authenticated
					$location.replace();
					$location.path("/login").search({ redirectTo: redirectUrl });
				}
			}
		});

		//check if the user is logged in, set that in the rootscope, and check if
		//the auth ticket hasn't expired yet
		$rootScope.loggedin = authService.loggedin();

		// retrieve the userproperties and load them in the rootscope
		if ($rootScope.loggedin) {
			$rootScope.userproperties = $localStorage.userproperties;
			$log.debug("userproperties loaded into rootscope from localStorage");
		}

		// run a timer that checks every x second if the auth ticket hasn't expired yet
		setInterval(function () {
			$rootScope.loggedin = authService.loggedin();
			$rootScope.$digest(); // because this runs outside the main angular loop
			// run digest to let angular know that this value has changed
		}, 10000);

		// watch the window size and make the lefthand navigation forcibly visible when we are on 'desktop'/md size
		angular.element($window).on("resize", function () {
			if ($window.innerWidth >= 992) {
				$rootScope.showMenu = true;
			}
			if ($window.innerWidth < 992) {
				$rootScope.showMenu = false;
			}
			$rootScope.$digest();
		});

		// make the lefthand navigation forcibly visible when we are on 'desktop'/md size on page load
		$rootScope.$on("$viewContentLoaded", function () {
			if ($window.innerWidth >= 992) {
				$rootScope.showMenu = true;
			}
		});
		$rootScope.getCurrentUserRoles = function () {
			return authService.getCurrentUserRoles();
		};
		// checks if a user is in a role
		$rootScope.isInRole = function (role) {
			return authService.isInRole(role);
		};

		//checks if a user is in any of the roles
		$rootScope.isInRoles = function (roles) {
			return authService.isInRoles(roles);
		};

		var getDashboardTitleTimeout = function () {
			if (!$rootScope.userproperties) {
				$timeout(getDashboardTitleTimeout); // Wait for all templates to be loaded
			} else {
				var currentHour = new Date().getHours();
				var friendlyTimeIndicator;

				// check if username has a whitespace
				if ($rootScope.userproperties.realName.indexOf(" ") >= 0) {
					var realName = $rootScope.userproperties.realName.substr(
						0,
						$rootScope.userproperties.realName.indexOf(" ")
					);
				} else {
					var realName = $rootScope.userproperties.realName;
				}

				if (currentHour >= 0 && currentHour <= 12) {
					friendlyTimeIndicator = "Goedemorgen, " + realName;
				}
				if (currentHour >= 12 && currentHour <= 18) {
					friendlyTimeIndicator = "Goedemiddag, " + realName;
				}
				if (currentHour >= 18 && currentHour <= 24) {
					friendlyTimeIndicator = "Goedenavond, " + realName;
				}

				return friendlyTimeIndicator;
			}
		};

		$rootScope.getDashboardTitle = function () {
			var title = getDashboardTitleTimeout();
			return title;
		};

		if ($rootScope.loggedin) {
			notificationservice.getNotifications();
		}
	});
