"use strict";

var module = angular.module("aanstellingspas.auditlog", [
  "smart-table",
  "jsonFormatter",
]);

module.config(function ($routeProvider) {
  $routeProvider.when("/auditlog/:type?/:id?/:contextId?", {
    templateUrl: "app/modules/auditlog/auditlog.view.html",
    controller: "AuditlogController",
    title: "Auditlog",
    navigation_id: "auditlog",
  });
});

module
  .controller("AuditlogController", [
    "$scope",
    "$http",
    "$log",
    "$localStorage",
    "notificationservice",
    "$resource",
    "backendBaseUrl",
    "$location",
    "$routeParams",
    function (
      $scope,
      $http,
      $log,
      $localStorage,
      notificationService,
      $resource,
      backendBaseUrl,
      $location,
      $routeParams
    ) {
      //collection of all audtilogs from backend
      $scope.collection = [];

      //collection of audtilogs displayed in view
      $scope.displayed = [];
      $scope.auditlogType = $routeParams.type;
      $scope.auditlogId = $routeParams.id;
      $scope.auditlogContextId = $routeParams.contextId;
      $scope.metadata = "";
      $scope.showModal = false;

      //if we got routeparams
      if ($scope.auditlogType && $scope.auditlogId) {
        $resource(
          backendBaseUrl +
            "/auditlog/" +
            $scope.auditlogType +
            "/" +
            $scope.auditlogContextId
        )
          .query()
          .$promise.then(
            function success(result) {
              $scope.collection = result;
            },
            function failure(result) {
              if (result.status === 404) {
                //notificationservice.info('Er zijn geen examens.');
              } else {
                $log.error(result);
              }
            }
          );
      } else {
        //get alle audtilogs for overzicht
        $resource(backendBaseUrl + "/auditlog")
          .query()
          .$promise.then(
            function success(result) {
              $scope.collection = result;
            },
            function failure(result) {
              if (result.status === 404) {
                //notificationservice.info('Er zijn geen examens.');
              } else {
                $log.error(result);
              }
            }
          );
      }
      $scope.showEntity = function (data) {
        $resource(
          backendBaseUrl +
            "/auditlog/" +
            $scope.auditlogType +
            "/" +
            $scope.auditlogId +
            "/" +
            $scope.auditlogContextId
        )
          .get()
          .$promise.then(
            function success(result) {
              $scope.metadata = result;
              $scope.showModal = true;
            },
            function failure(result) {
              if (result.status === 404) {
                //notificationservice.info('Er zijn geen examens.');
              } else {
                $log.error(result);
              }
            }
          );
      };

      $scope.showMetadata = function (data) {
        $scope.metadata = JSON.parse(data);
        $scope.showModal = true;
      };

      $scope.closeMetadata = function () {
        $scope.metadata = "";
        $scope.showModal = false;
      };
    },
  ])
  .directive("auditlog", [
    "$resource",
    "backendBaseUrl",
    "$log",
    function ($resource, backendBaseUrl, $log) {
      return {
        restrict: "E",
        scope: {
          auditlogContextId: "=",
        },
        templateUrl: "app/modules/auditlog/auditlog-directive.view.html",
        link: function ($scope, element, attrs) {
          //collection of all audtilogs from backend
          $scope.collection = [];
          $scope.doneLoading = false;
          $scope.loading = false;

          //collection of audtilogs displayed in view
          $scope.displayed = [];
          $scope.metadata = "";
          $scope.showModal = false;

          $scope.fetch = function () {
            $scope.doneLoading = false;
            $scope.loading = true;
            if ($scope.auditlogContextId) {
              $resource(
                backendBaseUrl + "/auditlog/:id",
                { id: $scope.auditlogContextId },
                {
                  query: {
                    ignoreLoadingBar: true,
                    isArray: true,
                  },
                }
              )
                .query()
                .$promise.then(
                  function success(result) {
                    $scope.collection = result;
                    $scope.doneLoading = true;
                    $scope.loading = false;
                  },
                  function failure(result) {
                    if (result.status === 404) {
                      //notificationservice.info('Er zijn geen examens.');
                    } else {
                      $log.error(result);
                    }
                    $scope.doneLoading = true;
                    $scope.loading = false;
                  }
                );
            }
          };
          $scope.showEntity = function (data) {
            $resource(
              backendBaseUrl +
                "/auditlog/" +
                $scope.auditlogType +
                "/" +
                $scope.auditlogId +
                "/" +
                $scope.auditlogContextId
            )
              .get()
              .$promise.then(
                function success(result) {
                  $scope.metadata = result;
                  $scope.showModal = true;
                },
                function failure(result) {
                  if (result.status === 404) {
                    //notificationservice.info('Er zijn geen examens.');
                  } else {
                    $log.error(result);
                  }
                }
              );
          };

          $scope.showMetadata = function (data) {
            $scope.metadata = JSON.parse(data);
            $scope.showModal = true;
          };

          $scope.closeMetadata = function () {
            $scope.metadata = "";
            $scope.showModal = false;
          };
        },
      };
    },
  ]);
